
import { mapActions, mapState } from 'pinia'
import { useSiteStore } from '~/store/site'

export default {
    computed: {
        ...mapState(useSiteStore, ['invoices', 'currentSite']),
    },
    methods: {
        ...mapActions(useSiteStore, ['loadInvoices'])
    },
    async mounted(){
        await this.loadInvoices()
    }
}
