
import BasicLayout from '~/layouts/basic'
import { mapState } from 'pinia'
import { useSiteStore } from '~/store/site'

export default {
    components: {
        BasicLayout
    },
    computed: {
        ...mapState(useSiteStore, [
            'currentSite',
        ]),
    },
    methods: {
        goToLink() {
            window.open('https://calendly.com/just-email-consultation/free-just-email-zoom-consultation', '_blank')
        }
    }
}
