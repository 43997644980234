
import BasicLayout from '~/layouts/basic'
import { mapState } from 'pinia'
import { useAddToCartStore } from '~/store/upsells/add_to_cart'
export default {
  components: {
    BasicLayout,
    vDropdown: () => import('v-dropdown')
  },
  props: {
    saveEnabled: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      upgradeModalOpen: false,
      intendedProIntegration: null,
    }
  },
  computed: {
    ...mapState(useAddToCartStore, ['addToCart', 'upsellAddToCartExampleProps', 'loading']),
  }
}
