import { render, staticRenderFns } from "./reviews.vue?vue&type=template&id=c4b8dbe6&scoped=true&lang=pug"
import script from "./reviews.vue?vue&type=script&lang=js"
export * from "./reviews.vue?vue&type=script&lang=js"
import style0 from "./reviews.vue?vue&type=style&index=0&id=c4b8dbe6&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4b8dbe6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccordionSection: require('/opt/build/repo/components/Editor/AccordionSection.vue').default,Accordion: require('/opt/build/repo/components/DataDisplay/Accordion.vue').default})
