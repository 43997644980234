
import { SkeletonTheme } from 'vue-loading-skeleton'

export default {
    components: {
        SkeletonTheme
    },
    data(){
        return {
            mobileDropdownOpen: false,
        }
    },
    props: {
        mobileDropdownLabel: {
            type: String,
            default: 'Example'
        },
        loading: {
            type: Boolean,
            default: () => false
        }
    },
}
