
import { mapActions, mapState, mapWritableState } from 'pinia'
import AddToCartEditorContentLayout from '~/layouts/upsells/addToCartEditorContent'
import { SkeletonTheme } from 'vue-loading-skeleton'
import { useUpsellsAddToCartDiscountsStore } from "~/store/upsells/add_to_cart/discounts";
import { useSiteStore } from "~/store/site";
import cloneDeep from "lodash/cloneDeep";
import { usePopsStore } from "~/store/pops";
import { useBaseOnboardingStore } from "~/store/onboardings/baseOnboarding";

export default {
    layout: 'upsells/addToCartEditor',
    components: {
        AddToCartEditorContentLayout,
        SkeletonTheme
    },
    data() {
        return {
            searchTerm: '',
            currentDiscount: {}
        }
    },
    mounted() {
        this.loadDiscounts({})
        if (this.skipDiscountSteps) return;
        this.showOnboardingStep('upsells_new_discount_selector');
    },
    computed: {
        ...mapState(useSiteStore, [
            'parsedLocale', 'currentSite'
        ]),
        ...mapState(usePopsStore, [
            'microExamplePop'
        ]),
        ...mapState(useUpsellsAddToCartDiscountsStore, [
            'discounts', 'loadingDiscounts', 'loadingShopifyDiscounts', 'processingDiscount'
        ]),
        ...mapState(useBaseOnboardingStore, ['skipDiscountSteps']),
        ...mapWritableState(useUpsellsAddToCartDiscountsStore, [
            'selectedShopifyDiscount', 'shopifyDiscounts'
        ]),
        template() {
            return this.templates.find(template => template.handle === 'shopify_discount') || {}
        },
        examplePopProps() {
            if(!this.currentDiscount?.content) return this.microExamplePop

            let examplePopProps = _.cloneDeep(this.microExamplePop)

            examplePopProps.pop.body = {
                ...$nuxt.$parseOpenEventBody(this.currentDiscount.content, examplePopProps.pop.variables)
            }

            return examplePopProps
        },
    },
    methods: {
        ...mapActions(useUpsellsAddToCartDiscountsStore, [
            'loadDiscounts', 'loadShopifyDiscounts', 'createDiscount', 'updateDiscount', 'deleteDiscount'
        ]),
        ...mapActions(useBaseOnboardingStore, ['showOnboardingStep']),
        handleSearchDiscount(title){
            this.loadShopifyDiscounts(title).then(() => {
                if (this.skipDiscountSteps) return;

                if (this.shopifyDiscounts.filter(discount => discount.selectable).length > 0) {
                    this.showOnboardingStep('upsells_choose_discount');
                } else if (this.shopifyDiscounts.length === 0) {
                    this.showOnboardingStep('upsells_select_discount');
                }
            })
        },
        handleShowOnboardingSearchStep(){
            if (this.skipDiscountSteps) return;
            this.showOnboardingStep('upsells_search_discount');
        },
        handleShowOnboardingSubmitStep(){
            if (this.skipDiscountSteps) return;
            this.showOnboardingStep('upsells_submit_discount');
        },
        handleShowOnboardingSelectorStep(){
            if (this.skipDiscountSteps) return;
            this.showOnboardingStep('upsells_new_discount_selector');
        },
        triggerSearch(){
            this.loadDiscounts({ title: this.searchTerm })
        },
        handleCreate(discount) {
            this.createDiscount(discount).then(() => this.triggerSearch())
            this.shopifyDiscounts = []
        },
        handleDelete(discount) {
            this.deleteDiscount(discount).then(() => this.triggerSearch())
        },
    }
}
