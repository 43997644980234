import { render, staticRenderFns } from "./UpsellsMetricsConversionAggregationsChart.vue?vue&type=template&id=99bec3da&scoped=true&lang=pug"
import script from "./UpsellsMetricsConversionAggregationsChart.vue?vue&type=script&lang=js"
export * from "./UpsellsMetricsConversionAggregationsChart.vue?vue&type=script&lang=js"
import style0 from "./UpsellsMetricsConversionAggregationsChart.vue?vue&type=style&index=0&id=99bec3da&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99bec3da",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BarChart: require('/opt/build/repo/components/Charts/BarChart.vue').default,HorizontalBarChart: require('/opt/build/repo/components/Charts/HorizontalBarChart.vue').default,EmptyChart: require('/opt/build/repo/components/EmptyState/EmptyChart.vue').default,Card: require('/opt/build/repo/components/Layout/Card.vue').default})
