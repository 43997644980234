
export default {
    props: {
        value: {
            type: Boolean,
            default: () => false,
        },
        title: {
            type: String,
            default: () => 'No title',
        },
        salesPopProps: {
            type: Object,
            default: () => {}
        },
        mode: {
            type: String,
            default: 'switch'
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        disabled: {
          type: Boolean,
          default: () => true,
        }
    }
}
