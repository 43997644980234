
import { mapWritableState } from 'pinia'
import { useTemplatesStore } from '~/store/templates'

export default {
    props: {
        allowDisable: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapWritableState(useTemplatesStore, [
            'currentTemplate'
        ]),
    },
}
