
    import { SkeletonTheme } from 'vue-loading-skeleton';

    export default {
        components: {
            SkeletonTheme,
        },
        props: {
            discounts: {
                type: Array,
                default: () => []
            },
            discount: {
                type: Object,
                default: () => ({})
            },
            loadingDiscounts: {
                type: Boolean,
                default: false
            },
            processingDiscount: {
                type: Boolean,
                default: false
            },
            parsedLocale: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                searchTerm: null,
                openDiscountSelectDialog: false,
            }
        },
        methods: {
            triggerSearch(searchTerm) {
                this.$emit('search-discount', { title: searchTerm })
            },
            handleDiscountSelect(discount) {
                this.discount = discount
                this.openDiscountSelectDialog = false
                this.$emit('select-discount', discount)
                this.$emit('show-submit-onboarding-step');
            },
            handleSmartDialogOpen() {
                this.openDiscountSelectDialog = true;
                this.$emit('show-search-onboarding-step');
            },
            saveDiscount() {
                this.$emit('save', this.discount)
                this.searchTerm = null
                this.discount = {}
            },
            handleShowOnboardingSelectorStep() {
                this.$emit('show-selector-onboarding-step');
            }
        }
    }
