
export default {
    props: {
        canSave: {
            type: Boolean,
            default: () => true,
        },
        tooltip: {
            type: String,
            default: () => ''
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        cancel: {
            type: Boolean,
            default: () => false
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        }
    }
}
