
export default {
    props: {
        header: {
            type: String,
            default: () => 'Table header'
        },
        hideHeader: {
            type: Boolean,
            default: () => false,
        }
    }
}
