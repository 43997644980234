
export default {
    props: {
        title: {
            type: String,
            default: 'title'
        },        
        meta: {
            type: String,
            default: 'metadata'
        },
        icon: {
            type: String,
        },
        image: {
            type: String,
        },
        color: {
            type: String,
        }
    }
}
