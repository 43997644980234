
import { mapState, mapWritableState } from 'pinia'
import { useUiStore } from '~/store/ui'
import { useTemplatesStore } from '~/store/templates'

export default {
    data: () => {
        return {
            error: false,
            validationTimeout: null,
        }
    },
    computed: {
        ...mapState(useUiStore, ['editingPopHandler']),
        ...mapWritableState(useTemplatesStore, ['currentTemplate']),
    },
    methods: {
        checkValue(v){
            if(!v) {
                this.currentTemplate.proof_minimum_activity_sessions = null
                return
            }
            clearTimeout(this.validationTimeout)
            this.error = false
            if(v < 2) {
                this.error = true
                this.validationTimeout = setTimeout(()=>{
                    this.error = false
                    this.currentTemplate.proof_minimum_activity_sessions = 2
                }, 300)
            }
        }
    }

}
