
import { mapActions, mapState } from 'pinia'
import { useSiteStore } from '~/store/site'

export default {
    data() {
        return {
            judgeMeToken: "",
            callbackModal: false
        }
    },
    watch: {
        tokenPresent: {
            immediate: true,
            handler(tokenPresent){
                if(tokenPresent) this.judgeMeToken = '***************************'
            }
        }
    },
    computed: {
        ...mapState(useSiteStore,['currentSite']),
        tokenPresent(){
            return this.currentSite.utils.judgeme_token_present
        }
    },
    methods: {
        ...mapActions(useSiteStore, ['connectToJudgeMe']),
    },
}
