import { render, staticRenderFns } from "./ProductSelect.vue?vue&type=template&id=6c4b1324&scoped=true&lang=pug"
import script from "./ProductSelect.vue?vue&type=script&lang=js"
export * from "./ProductSelect.vue?vue&type=script&lang=js"
import style0 from "./ProductSelect.vue?vue&type=style&index=0&id=6c4b1324&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c4b1324",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductView: require('/opt/build/repo/components/DataDisplay/ProductView.vue').default,Button: require('/opt/build/repo/components/DataInput/Button.vue').default,SmartDialog: require('/opt/build/repo/components/Layout/SmartDialog.vue').default})
