
import { mapWritableState, mapActions, mapState } from 'pinia'
import { useUiStore } from '~/store/ui'

import keys from 'lodash/keys'
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'
import { useBaseOnboardingStore } from '~/store/onboardings/baseOnboarding'
import { usePopMetricsStore } from '~/store/pops/metrics'

export default {
  watch: {
    loadingPreflight: {
      handler: 'onLoadingPreflightChange',
      immediate: true
    }
  },
  computed: {
    ...mapState(useSiteStore, ['currentSite']),
    ...mapState(usePricingStore, ['plan']),
    ...mapWritableState(useUiStore, [
      'sidebarIsOpen',
      'loadingPreflight',
      'planChangeModal',
      'defaultTemplateHandle'
    ]),
    ...mapState(usePopMetricsStore, [
      'loadingOverviewConversionsTotals'
    ]),
  },
  methods: {
    keys,
    ...mapActions(useUiStore, ['setTemplateHandler']),
    ...mapActions(useBaseOnboardingStore, ['loadShopOnboardings']),

    onLoadingPreflightChange(loading) {
      if (loading) return

      this.setTemplateHandler(this.$route.query.pop || this.defaultTemplateHandle)
    },
  }
}
