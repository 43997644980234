
import { mapActions, mapState } from 'pinia'
import { useTemplatesStore } from '~/store/templates'
export default {
    methods: {
        ...mapActions(useTemplatesStore, [
          'addPageToExcluded',
          'removePageFromExcluded',
          'addPageToIncluded',
          'removePageFromIncluded',
        ]),
        handleExcludeSubmit(v){
            this.addPageToExcluded('/' + v )
            this.$refs.filterInput.reset()
        },
        handleIncludeSubmit(v) {
          this.addPageToIncluded('/' + v)
          this.$refs.filterInput.reset()
        },
        toggleDisplayPagesMode(mode){
          this.currentTemplate.proof_display_pages_mode = mode
        }
    },
    computed: {
        ...mapState(useTemplatesStore, ['currentTemplate']),
        excludedPages(){
            return this.currentTemplate.proof_exclude_pages
        },
        includedPages() {
          return this.currentTemplate.proof_include_pages
        },
        includedPagesEmpty() {
          return this.includedPages && this.includedPages.length === 0
        },
        displayPagesMode() {
          return this.currentTemplate.proof_display_pages_mode
        },
        pagesToggleValues() {
          return [
            {
              mode: 'included',
              icon: 'plus',
            },
            {
              mode: 'excluded',
              icon: 'times',
            }
          ]
        },
    }
}
