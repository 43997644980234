
import EditorContentLayout from '~/layouts/editorContent.vue'
import BasicLayout from '~/layouts/basic.vue'
import ManualReviewForm from '~/components/Forms/ManualReviewForm.vue'
import { mapState } from 'pinia'
import { mapActions } from 'pinia'
import { useEditorStore } from '~/store/editor'
import { useReviewsStore } from '~/store/reviews'
import { useTemplatesStore } from '~/store/templates'

export default {
    layout: 'editor',
    components: {
        EditorContentLayout,
        BasicLayout,
        ManualReviewForm
    },
    computed: {
        ...mapState(useEditorStore, ['currentFormsByHandle']),
        ...mapState(useTemplatesStore, {
            templateHandle: s => s.currentTemplate.handle
        }),
    },
    methods: {
        ...mapActions(useReviewsStore, ['createManualReview']),
        ...mapActions(useTemplatesStore,[
            'saveCurrentTemplate',
            'SET_VALID_FORM',
            'SET_INVALID_FORM'
        ]),
        setValidityOfForm(form, valid){
            if(valid){
                this.SET_VALID_FORM(form)
            } else {
                this.SET_INVALID_FORM(form)
            }

        },
        async handleSave(){
            try {
                await this.saveCurrentTemplate()
            } catch (e) {
                console.error(e)
            }
        },
    },
    watch: {
        templateHandle: {
            immediate: true,
            handler(templateHandle){
                if(templateHandle === 'shopify_review') return
                this.$router.push({query: {pop: 'shopify_review'}})
            }
        }
    }
}
