
import BasicLayout from '~/layouts/basic.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Dropdown from 'v-dropdown'
import { mapState, mapWritableState } from 'pinia'
import { useUiStore } from '~/store/ui'
import { usePricingStore } from '~/store/pricing'
import { useUpsellsMetricsStore } from "~/store/upsells/metrics";
import { useSiteStore } from '~/store/site'

export default {
    head() {
        return {
            title: 'SalesPop | Metrics',
        }
    },
    components: {
        BasicLayout,
        'v-dropdown': Dropdown,
        DatePicker
    },
    data(){
        return {
            dateRange: new Date(),
            selectedPeriod: {},
            selectedPeriodLabel: 'Today',
            usingDatePicker: false,
        }
    },
    created() {
      this.handlePeriodSelect(this.metricPeriods.find(period => period.label === this.selectedPeriodLabel))

      if(!this.loading && !this.$isPro()){
          this.redirectToDashboard()
      }
    },
    destroyed() {
      useUpsellsMetricsStore().$reset()
    },
    watch: {
        dateRange(range) {
            this.usingDatePicker = true

            const newFrom = this.$moment(range[0])
            const newTo = this.$moment(range[1])

            if(!newFrom.isValid() || !newTo.isValid()) return

            this.selectedPeriod = { current: { from: newFrom, to: newTo } }

            if(newFrom.format('DD/MM/YYYY') === newTo.format('DD/MM/YYYY')) {
                this.selectedPeriodLabel = `${newFrom.format('LL')}`
            } else {
                this.selectedPeriodLabel = `${newFrom.format('LL')} - ${newTo.format('LL')}`
            }
        },
        loading(){
          if(!this.$isPro()) {
            this.redirectToDashboard()
          }
        },
    },
    methods: {
        handlePeriodSelect(period) {
            this.usingDatePicker = false
            if(this.selectedPeriod === period) return
            this.selectedPeriod = period
            this.selectedPeriodLabel = period.label
            this.$refs.periodDropdown?.visible?.()
        },
        redirectToDashboard() {
          this.$router.push('/dashboard')
          this.showRedirectToDashboardChangeModal = true;
        },
    },
    computed: {
        ...mapState(usePricingStore, ['plan']),
        ...mapState(useUiStore, [
            'loadingPreflight',
            'metricPeriodsList',
            'metricPeriods'
        ]),
        ...mapState(useUpsellsMetricsStore, [
            'loadingActionsCount',
        ]),
      ...mapState(useSiteStore, ['loading']),
      ...mapWritableState(useUiStore, ['showRedirectToDashboardChangeModal']),
        loadingMetrics() {
          return this.loadingActionsCount
        },
        unsafeDates(){
            const startDate = this.$moment('08/09/2020', 'DD/MM/YYYY')
            const { current } = this.selectedPeriod
            return current?.from?.isSameOrBefore(startDate)
        },
    }
}
