
import { usePopMetricsStore } from "~/store/pops/metrics";
import { useIntegrationsStore } from "~/store/integrations";
import { mapActions, mapState } from 'pinia';
import numeral from "numeral";

export default {
  props: {
    period: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      omittedTemplateHandles: [],
      dataMap: {},
      chartOptions: {
        scales: {
          yAxes: [
            {
              gridLines: {display: false},
              ticks: {display: true, min: 0},
            }
          ]
        },
        cornerRadius: 7,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.datasets[tooltipItem.datasetIndex].label
              let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

              return `${label}: ${numeral(value).format('0,0')}`
            },
          }
        }
      },
    }
  },
  watch: {
    period: {
      deep: true,
      immediate: true,
      handler(value) {
        if(!value) return

        this.omittedTemplateHandles = []
        this.actionDataMap[this.action].loadData({ startDate: value.from, endDate: value.to })
      }
    }
  },
  methods: {
    ...mapActions(usePopMetricsStore, [
      'colorFromMD5',
      'loadTemplatesViewsAggregations',
      'loadTemplatesClicksAggregations',
    ]),
    handleFiltering(templateHandle) {
      if (this.isOmittedTemplateHandle(templateHandle)) {
        this.omittedTemplateHandles = this.omittedTemplateHandles.filter(
            omittedTemplateHandle => omittedTemplateHandle !== templateHandle
        )
      } else {
        this.omittedTemplateHandles.push(templateHandle)
      }
    },
    isOmittedTemplateHandle(pop) {
      return this.omittedTemplateHandles.includes(pop)
    },
    templateHandleTitle(templateHandle) {
      return this.integrationHandleMap[templateHandle]?.title
    },
  },
  computed: {
    ...mapState(usePopMetricsStore, [
      'templatesViewsAggregations',
      'templatesClicksAggregations',
      'granularityData',
    ]),
    ...mapState(useIntegrationsStore, [
      'integrationHandleMap',
    ]),
    chartData() {
      let datasets = []
      let labels = []
      this.dataMap = {}
      let templateHandles = this.aggregationsData.template_handles

      templateHandles.forEach((templateHandle) => {
        this.dataMap[templateHandle] = {
          color: this.colorFromMD5(templateHandle),
          data: [],
        }
      })

      Object.entries(this.aggregationsData.aggregations).forEach(([date, data]) => {
        labels.push(this.$moment.utc(date).format(this.dateFormat))

        Object.keys(this.dataMap).forEach((key) => {
          this.dataMap[key].data.push(data[key])
        })
      })

      let filteredData = Object.keys(this.dataMap).reduce((data, templateHandle) => {
        if (!this.omittedTemplateHandles.includes(templateHandle)) {
          data[templateHandle] = this.dataMap[templateHandle]
        }

        return data
      }, {})

      Object.keys(filteredData).forEach((key) => {
        datasets.push({
          label: this.templateHandleTitle(key),
          backgroundColor: this.dataMap[key]?.color,
          data: this.dataMap[key]?.data,
          stack: key
        })
      })

      return {labels, datasets, period: this.period}
    },
    aggregationsDataExists() {
      return this.aggregationsData.template_handles.length
    },
    dateFormat() {
      return this.granularityData.dateFormats[this.aggregationsData.granularity]
    },
    granularityText() {
      if(!this.aggregationsData.template_handles.length) return

      return this.granularityData.texts[this.aggregationsData.granularity]
    },
    aggregationsData() {
      return this.actionDataMap[this.action].aggregationsData || { template_handles: [] }
    },
    loading() {
      return this.actionDataMap[this.action].loading
    },
    actionDataMap() {
      return {
        popViews: {
          headerText: `Views by Pop Type`,
          loadData: this.loadTemplatesViewsAggregations,
          loading: this.loadingTemplatesViewsAggregations,
          aggregationsData: this.templatesViewsAggregations
        },
        popClicks: {
          headerText: `Clicks by Pop Type`,
          loadData: this.loadTemplatesClicksAggregations,
          loading: this.loadingTemplatesClicksAggregations,
          aggregationsData: this.templatesClicksAggregations
        }
      }
    }
  }
}
