
import { mapWritableState } from 'pinia'
import { useTemplatesStore } from '~/store/templates'

export default {
    computed: {
        ...mapWritableState(useTemplatesStore, ['currentTemplate']),
        currentPopType: {
            get(){
                if(this.currentTemplate.proof_show_media && this.currentTemplate.proof_show_message) return 'media+message'
                if(this.currentTemplate.proof_show_media && !this.currentTemplate.proof_show_message) return 'media'
                if(!this.currentTemplate.proof_show_media && this.currentTemplate.proof_show_message) return 'message'

            },
            set(currentPopType){
                const switchMap = {
                    'media+message': () => {
                        this.currentTemplate.proof_show_media = true
                        this.currentTemplate.proof_show_message = true
                    },
                    'media': () => {this.currentTemplate.proof_show_media = true; this.currentTemplate.proof_show_message = false},
                    'message': () => {this.currentTemplate.proof_show_media = false; this.currentTemplate.proof_show_message = true},
                }

                switchMap[currentPopType]()
            }
        },
        popLayouts() {
            return [{
                value: 'media+message',
                title: 'Media + Message',
                description: 'A captivating message and special media object you can design with our powerful editor.',
                image: '/illustrations/nudge/media-message.svg',
            },{
                value: 'media',
                title: 'Media Only',
                description: 'A special media object you can design with our powerful editor.',
                image: '/illustrations/nudge/media-only.svg',
            },{
                value: 'message',
                title: 'Message Only',
                description: 'A captivating message and a special icon from our library or your own.',
                image: '/illustrations/nudge/message-only.svg',
            }]
        }
    }
}
