
import { mapWritableState } from 'pinia'
import { useTemplatesStore } from "~/store/templates";

export default {
    computed: {
        ...mapWritableState(useTemplatesStore, ['currentTemplate']),
        timeIntervalMinutes: {
            get() {
                return this.currentTemplate.proof_abandoned_cart_time_interval_in_seconds / 60
            },
            set(value) {
                if(!value) return
                if(+value < 0) {
                    return this.currentTemplate.proof_abandoned_cart_time_interval_in_seconds = 0
                } else {
                    return this.currentTemplate.proof_abandoned_cart_time_interval_in_seconds = (+value * 60)
                }
            }
        }
    }
}
