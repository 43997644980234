
import EditorContentLayout from '~/layouts/editorContent.vue'
import BasicLayout from '~/layouts/basic.vue'
import { mapState, mapActions } from 'pinia'
import { useEditorStore } from '~/store/editor'
import { useTemplatesStore } from '~/store/templates'

export default {
    layout: 'editor',
    components: {
        EditorContentLayout,
        BasicLayout
    },
    computed: {
        ...mapState(useEditorStore, ['currentFormsByHandle'])
    },
    methods: {
        ...mapActions(useTemplatesStore, [
            'saveCurrentTemplate',
            'SET_VALID_FORM',
            'SET_INVALID_FORM'
        ]),
        setValidityOfForm(form, valid){
            if(valid){
                this.SET_VALID_FORM(form)
            } else {
                this.SET_INVALID_FORM(form)
            }
        },
        async handleSave(){
            try {
                await this.saveCurrentTemplate()
            } catch (e) {
                console.error(e)
            }
        },
    }
}
