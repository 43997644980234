import { render, staticRenderFns } from "./Drawer.vue?vue&type=template&id=273e1c40&scoped=true&lang=pug"
import script from "./Drawer.vue?vue&type=script&lang=js"
export * from "./Drawer.vue?vue&type=script&lang=js"
import style0 from "./Drawer.vue?vue&type=style&index=0&id=273e1c40&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "273e1c40",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmptyMenuItem: require('/opt/build/repo/components/EmptyState/EmptyMenuItem.vue').default,Button: require('/opt/build/repo/components/DataInput/Button.vue').default,GenericDrawer: require('/opt/build/repo/components/Layout/Drawer/GenericDrawer.vue').default})
