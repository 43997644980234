
import { mapState } from 'pinia'
import { useTemplatesStore } from '~/store/templates'

export default {
    computed: {
        ...mapState(useTemplatesStore, ['currentTemplate']),
    },

}
