
import AddToCartEditorContentLayout from '~/layouts/upsells/addToCartEditorContent'
import BasicLayout from '~/layouts/basic'
import AddToCartDesignEditor from '~/components/Forms/AddToCartDesignEditor'
import { mapActions } from 'pinia'
import { useAddToCartStore } from '~/store/upsells/add_to_cart'

export default {
  layout: 'upsells/addToCartEditor',
  components: {
    AddToCartEditorContentLayout,
    BasicLayout,
    AddToCartDesignEditor
  },
  methods: {
    ...mapActions(useAddToCartStore, ['saveAddToCart']),
    async handleSave() {
      try {
        await this.saveAddToCart()
      } catch (e) {
        console.error(e)
      }
    },
  }
}
