import { render, staticRenderFns } from "./PopMediaTemplates.vue?vue&type=template&id=3622b08c&scoped=true&lang=pug"
import script from "./PopMediaTemplates.vue?vue&type=script&lang=js"
export * from "./PopMediaTemplates.vue?vue&type=script&lang=js"
import style0 from "./PopMediaTemplates.vue?vue&type=style&index=0&id=3622b08c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3622b08c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/opt/build/repo/components/DataInput/Button.vue').default})
