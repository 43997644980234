
import BasicLayout from '~/layouts/basic'
import { isEqual, cloneDeep } from 'lodash'
import {mapActions, mapState, mapWritableState} from 'pinia'
import { useUiStore } from '~/store/ui'
import { useSiteStore } from '~/store/site'
import { useBaseOnboardingStore } from "~/store/onboardings/baseOnboarding";

export default {
    head() {
        return {
            title: 'SalesPop | Pop Rules & Settings',
        }
    },
    components: {
        BasicLayout
    },
    data(){
        return {
            visibleSection: null,
            originalSite: null,
            confirmLeave: false,
            leavePath: null,
            isDirty: false,
        }
    },
    computed: {
        ...mapState(useSiteStore, {
            loadingCurrentSite: 'loading',
        }),
        ...mapState(useBaseOnboardingStore, ['currentOnboarding']),
        ...mapWritableState(useUiStore, [
            'loadingPreflight',
            'planChangeModal',
            'popRules',
        ]),
        ...mapWritableState(useSiteStore, [
            'currentSite',
        ]),
        activeScreens:{
            get(){
                return this.popRules.popDevices
                    .filter(({settingsKey}) => {
                        return this.currentSite[settingsKey]
                    })
                    .map(({text}) => text)
            },
            set(newDevices){
                this.setNewDevices(newDevices)
            }
        },
        filteredPopRulesMenu(){
            return this.popRules.drawerMenuButtons.filter(menuItem => {
                if(menuItem.source === undefined ) return true
                return menuItem.source === this.$source()
            })
        }
    },
    watch: {
        loadingPreflight: {
            immediate: true,
            async handler(loading){
                if(loading) return
                await this.$track('page_view/pop_rules')
                if(this.$route.query.scrollTo) {
                    setTimeout(() => this.scrollSection({}, this.$route.query.scrollTo), 1200)
                }
            }
        },
        currentSite: {
            deep: true,
            handler(){
                this.isDirty = !isEqual(this.currentSite, this.originalSite)
            }
        },
    },
    async mounted(){
        await this.loadCurrentSite()
        this.originalSite = cloneDeep(this.currentSite)
    },
    beforeRouteLeave(to, from, next){
        if(this.isDirty && !this.currentOnboarding) {
            this.leavePath = to.path
            this.confirmLeave = true

            return next(false)
        }

        next()
    },
    methods: {
        ...mapActions(useSiteStore, [
            'saveSiteSettings',
            'setNewDevices',
            'loadCurrentSite'
        ]),
        ...mapActions(useBaseOnboardingStore, ['showOnboardingStep']),
        scrollSection(event, slug){
            event?.preventDefault?.()
            event?.stopPropagation?.()
            const el = this.$refs?.[slug]
            if(!el) return
            el.scrollIntoView({behavior: "smooth"})
            el.classList.add('scroll-highligth')

            setTimeout(()=>{
                el.classList.remove('scroll-highligth')
            }, 700)
        },
        handleSizeChangeClick($event){
            if(!this.$isPro()) {
                this.planChangeModal = true
                $event.stopPropagation()
            }
        },
        async handleSave({ leave }) {
            if(!this.isDirty && !this.loadingCurrentSite) {
                this.showOnboardingStep('pops_general_settings_back')
            }

            if(this.isDirty || this.loadingCurrentSite) {
                this.showOnboardingStep('pops_save_general_settings')
            }

            if(!this.isDirty) return

            await this.saveSiteSettings()

            this.isDirty = false

            if (!this.isDirty && !this.loadingCurrentSite) {
                this.showOnboardingStep('pops_general_settings_back')
            }

            if(leave) { this.$router.push(this.leavePath) }
        },
        handleLeave() {
            this.isDirty = false
            this.$router.push(this.leavePath)
        }
    },
}
