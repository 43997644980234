
import { mapWritableState } from 'pinia'
import { required, minLength, between } from 'vuelidate/lib/validators'
import { useReviewsStore } from '~/store/reviews'

export default {
    props: {
        saveCallback: {
            type: Function,
            required: true
        }
    },
    data(){
        return {
            contentPlaceholder: `Next time I will order a large rather than an XL. I'd rather wear a shirt a little too big than one that's too small. I think large would be just right. I love the softness of the cotton, and the color is beautiful. I've never bought a t-shirt of this quality for under $30.`,
            isRealReview: false,
        }
    },
    computed: {
        ...mapWritableState(useReviewsStore, {
            manualReview: 'manualReview',
        }),
    },
    methods: {
        async saveManualReview(){
            await this.saveCallback()
        }
    },
    validations: {
        manualReview: {
            body: {
                required
            },
            product: {
                required
            },
            reviewer_name: {
                required
            },
            location: {
                required
            }
        },
        isRealReview: {
            mustBeTrue: v => v
        }
    }
}
