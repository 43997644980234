
export default {
    props: {
        icon: {
            type: String,
            default: 'check',
        },
        text: {
            type: String,
            default: 'text',
        },
        to: {
            type: String,
            default: '',
        },
        level: {
            type: Number,
            default: 0,
        },
        external: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
