
import { required } from 'vuelidate/lib/validators'
import { mapWritableState, mapActions } from 'pinia'
import { useAddToCartStore } from '~/store/upsells/add_to_cart'

export default {
  async mounted() {
    await this.loadAddToCart()
  },
  validations: {
    backgroundColor: {
      required
    },
    fontColor: {
      required
    }
  },
  methods: {
    ...mapActions(useAddToCartStore, ['loadAddToCart']),
    changeColorValue(key, value) {
      this.addToCart[key] = value
    },
    getColorValue(key) {
      return this.addToCart[key]
    }
  },
  computed: {
    ...mapWritableState(useAddToCartStore, ['addToCart']),
    colorForms() {
      return [{
        label: 'Title Color',
        key: 'title_color',
        id: 'upsellsRulesAndSettingAddToCartDesignChangeTitleColor',
      },{
        label: 'Quantity box Color',
        key: 'quantity_box_color',
      }, {
        label: 'Discount top Content Color',
        key: 'content_top_color',
      }, {
        label: 'Discount middle Content Color',
        key: 'content_middle_color',
      }, {
        label: 'Add button background Color',
        key: 'add_button_background_color',
      }, {
        label: 'Add button border Color',
        key: 'add_button_border_color',
      }, {
        label: 'Add button font Color',
        key: 'add_button_font_color',
      }, {
        label: 'Skip button background Color',
        key: 'skip_button_background_color',
      }, {
        label: 'Skip button border Color',
        key: 'skip_button_border_color',
      }, {
        label: 'Skip button font Color',
        key: 'skip_button_font_color',
      },]
    },
    colors() {
      return [
        '#55efc4',
        '#81ecec',
        '#74b9ff',
        '#a29bfe',
        '#ffeaa7',
        '#fab1a0',
        '#ff7675',
        '#fd79a8',
        '#dfe6e9',
        '#636e72',
        '#ffffff',
        '#00b894',
        '#00cec9',
        '#0984e3',
        '#6c5ce7',
        '#fdcb6e',
        '#e17055',
        '#d63031',
        '#e84393',
        '#b2bec3',
        '#2d3436',
        '#f2f6fa'
      ]
    }
  }
}
