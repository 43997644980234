

export default {
    props: {
        upsellsSiteEvents: {
            type: Array,
            default: () => []
        }
    },
    methods: {
      getType(upsell_type, upsell_action) {
        const typeMapping = {
          add_to_cart: 'Add To Cart'
        }

        const actionMapping = {
          add_to_cart_button_click: 'Button Click',
          add_to_cart_view: 'View',
          add_to_cart_close: 'Close',
        }

        return `${typeMapping[upsell_type]} ${actionMapping[upsell_action]}`
      }
    }
}
