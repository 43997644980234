
export default {
    props: {
        ignorePadding: {
            type: Boolean,
            default: ()=> false,
        },
        header: {
            type: String,
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        tooltip: {
            type: String,
            default: ''
        },
        tooltipHideDelay: {
          type: Number,
          default: 0
        }
    }
}
