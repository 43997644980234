import { render, staticRenderFns } from "./ErrorTag.vue?vue&type=template&id=6e7f804c&scoped=true&lang=pug"
import script from "./ErrorTag.vue?vue&type=script&lang=js"
export * from "./ErrorTag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e7f804c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tag: require('/opt/build/repo/components/DataDisplay/Tag.vue').default})
