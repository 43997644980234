
import Color from 'color'
export default {
    props: {
        icon: {
            type: String,
            default: 'bell'
        },
        // Use tailwind colors
        color: {
            type: String,
            default: '#6F58FF'
        },
        value: {
            type: String,
            default: 'sample tag'
        },
        size: {
            type: String,
            default: 'normal',
        }
    },
    computed: {
        cssVars(){
            return {
                '--color': this.color,
                '--bg-color': Color(this.color).fade(0.9),
            }
        }
    }
}
