
export default {
  props: {
    uniqSessionsPerVisitor: {
      type: Number,
    }
  },
  computed: {
    uniqSessions: {
      get() {
        return this.uniqSessionsPerVisitor
      },
      set(sessions) {
        if (!sessions) return
        this.uniqSessionsPerVisitorInput(sessions)
      }
    },
    checked() {
      return this.uniqSessions === 0
    }
  },
  methods: {
    uniqSessionsPerVisitorInput(e) {
      this.$emit('update:uniqSessionsPerVisitor', e)
      this.$emit('change')
    },
    handleCheckboxClick() {
      if (this.uniqSessions) {
        this.uniqSessionsPerVisitorInput(0)
      } else {
        this.uniqSessionsPerVisitorInput(1)
      }
    }
  }
}
