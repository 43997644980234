
import { required, minLength, between } from 'vuelidate/lib/validators'
import { mapWritableState } from 'pinia'
import { usePopsStore } from '~/store/pops'
import { useTemplatesStore } from '~/store/templates'
import get from 'lodash/get'

export default {
    props: {
        enabledForms: {
            type: Array,
            default: () => []
        }
    },
    validations: {
        backgroundColor: {
            required
        },
        fontColor: {
            required
        }
    },
    methods: {
        changeColorValue(key, value){
            this.currentTemplate[key] = value
        },
        getColorValue(key){
            return get(this, key)
        }
    },
    computed: {
        ...mapWritableState(usePopsStore, ['forceShowIcon']),
        ...mapWritableState(useTemplatesStore, ['currentTemplate']),
        colorForms(){
            return [{
                label: 'Background Color',
                vModel: 'proof_background_color',
                placeholder: '#fff',
                id: 'popsRulesAndSettingChangeBackgroundColor',
            },{
                label: 'Text Color',
                vModel: 'proof_font_color',
                placeholder: '#000'
            },{
                label: 'Highlights Color',
                description: 'Review stars and highlighted text',
                vModel: 'proof_highlights_color',
            },{
                label: 'Icon Color',
                vModel: 'proof_icon_color',
                placeholder: '#000',
                mouseOver: () => this.forceShowIcon = true,
                mouseLeave: () => this.forceShowIcon = false
            },{
                label: 'Icon Background Color',
                vModel: 'proof_icon_background_color',
                placeholder: '#000',
                mouseOver: () => this.forceShowIcon = true,
                mouseLeave: () => this.forceShowIcon = false
            }]
        },
        filteredForms(){
            if(this.enabledForms.length === 0) return this.colorForms
            return this.colorForms.filter(cf => this.enabledForms.includes(cf.vModel))
        },
        colors(){
            return [
                '#55efc4',
                '#81ecec',
                '#74b9ff',
                '#a29bfe',
                '#ffeaa7',
                '#fab1a0',
                '#ff7675',
                '#fd79a8',
                '#dfe6e9',
                '#636e72',
                '#ffffff',
                '#00b894',
                '#00cec9',
                '#0984e3',
                '#6c5ce7',
                '#fdcb6e',
                '#e17055',
                '#d63031',
                '#e84393',
                '#b2bec3',
                '#2d3436',
                '#f2f6fa'
            ]
        }
    }
}
