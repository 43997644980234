
import {mapState} from 'pinia'
import {useSiteStore} from '~/store/site'

export default {
    computed: {
        ...mapState(useSiteStore, ['currentSite']),
        pricingPlanName() {
            return this.currentSite.pricing_plan?.name
        },
        pricingPlanDowngradeToName() {
            return this.currentSite.pricing_plan_downgrade_to?.name
        }
    }
}
