
export default {
    props: {
        displayTime: {
            type: [String, Number],
        },
        startDelayTime: {
            type: [String, Number],
        },
        timeBetween: {
            type: [String, Number],
        },
        cycle: {
            type: Boolean
        }
    },
    methods: {
        displayTimeInput(e) {
            this.$emit('update:displayTime', e.target.value)
            this.$emit('change')
        },
        startDelayTimeInput(e) {
            this.$emit('update:startDelayTime', e.target.value)
            this.$emit('change')
        },
        timeBetweenInput(e) {
            this.$emit('update:timeBetween', e.target.value)
            this.$emit('change')
        },
        cycleInput(e){
            this.$emit('update:cycle', e)
            this.$emit('change')
        }
    }
}
