
import { mapState, mapActions, mapWritableState} from 'pinia'
import { useMediaEditorStore } from '~/store/mediaEditor'
import { useTemplatesStore } from '~/store/templates'

export default {
    data(){
        return {
            content: 'hello',
            iconEnabled: true,
            iconMode: 'icon',
            selectedIcon: '',
            iconUrl: null,
            icons: [
                'gift',
                'bed',
                'bright',
                '',
                'building',
                'calculator',
                '',
                'coffee',
                'newspaper',
                // 'recycle',
                'fire',
                'filter',
                'pizza-slice',
                'bed-double',
                'star-half-alt',
                'users-alt',
                'atom',
                'flask',
                'backpack',
                'dna'
            ],
            mediaEditorIsOpen: false,
        }
    },
    async mounted(){
        await this.loadMediaEditorProjects()
    },
    computed: {
        ...mapState(useMediaEditorStore, {
            editorProjects: 'projects',
        }),
        ...mapState(useTemplatesStore, ['bodyObject']),
        ...mapWritableState(useTemplatesStore, ['currentTemplate']),
        stringBody(){
            return `<top>${this.content}</top><middle></middle>`
        },
        // Unicons hardcoded names
        iconProvider: {
            get(){
                const iconName = this.currentTemplate.proof_icon_url?.split('/').slice(-1)[0] ?? `gift`
                return iconName.replace('.svg','')
            },
            set(iconName){
                this.currentTemplate.proof_icon_url = `https://cdn.jsdelivr.net/npm/@iconscout/unicons@4.0.0/svg/line/${iconName}.svg`
            }
        },
        selectedProject(){
            const select = this.editorProjects.find(p => p.project_id == this.currentTemplate.proof_dh_project_id)
            if(select) return select
            return this.editorProjects[0] ?? {}
        },
    },
    methods: {
        ...mapActions(useMediaEditorStore, [
            'loadMediaEditorProjects',
            'getExportUrl'
        ]),
        ...mapActions(useTemplatesStore, [
            'saveCurrentTemplate'
        ]),
        openMediaEditor(){
            this.mediaEditorIsOpen = true
        },
        async saveProject(projectId){
            try {
                const url = await this.getExportUrl({projectId: projectId})
                this.currentTemplate.proof_media_url = url
                this.currentTemplate.proof_dh_project_id = projectId
                await this.saveCurrentTemplate()
                this.mediaEditorIsOpen = false
            } catch (e) {
                console.error(e)
            }
        },
    },
    watch: {
        stringBody(newString, oldString) {
            useTemplatesStore().SET_CURRENT_TEMPLATE_BODY(newString)
        },
        bodyObject:{
            immediate: true,
            handler(body = {}){
                this.content = body.top
            },
        },
    }
}
