
import { mapWritableState } from 'pinia'
import { useTemplatesStore } from '~/store/templates'

export default {
    computed: {
        ...mapWritableState(useTemplatesStore, ['currentTemplate']),
    },
    watch: {
        proof_display_review(proof_display_review){
            if(proof_display_review) return
            this.currentTemplate.proof_show_review_on_hover = false
        }
    }
}
