import { render, staticRenderFns } from "./CsvReviewsForm.vue?vue&type=template&id=a4be459c&scoped=true&lang=pug"
import script from "./CsvReviewsForm.vue?vue&type=script&lang=js"
export * from "./CsvReviewsForm.vue?vue&type=script&lang=js"
import style1 from "./CsvReviewsForm.vue?vue&type=style&index=1&id=a4be459c&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4be459c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/opt/build/repo/components/DataInput/Button.vue').default})
