
import { SkeletonTheme } from 'vue-loading-skeleton'
import { mapState } from 'pinia'
import { useUiStore } from '~/store/ui'
import { useSiteStore } from '~/store/site'

export default {
    name: 'basic',
    components: {
        SkeletonTheme
    },
    transition: {
        name: 'fade',
        mode: 'out-in'
    },
    props: {
        title: {
            type: String
        },
        subtitle: {
            type: String
        },
        hideTitle: {
            type: Boolean
        },
        dotted: {
            type: Boolean,
            default: () => false,
        },
        mode: {
            type: String,
        },
        tabs: {
            type: Array,
        },
        selectedTab: {
            type: Number,
        },
        hideTop: {
            type: Boolean,
            default: () => false
        },
        hideAppEnabledToggle: {
            type: Boolean,
            default: () => false
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        showTitleImmediately: {
          type: Boolean,
          default: () => false,
        },
        showSubtitleImmediately: {
          type: Boolean,
          default: () => false,
        }
    },
    methods: {
        handleTabClick(idx){
            this.$emit('tab-click', idx)
        },
    },
    computed: {
        ...mapState(useSiteStore, ['currentSite']),
        ...mapState(useUiStore, ['loadingPreflight']),
        daysRemaining(){
            return this.currentSite?.utils?.days_remaining_on_free_trial ?? 0
        },
        freePlanLimitReached(){
            return this.currentSite?.utils?.free_plan_limit_reached ?? true
        }
    }
}
