import { render, staticRenderFns } from "./DaysRemainingAnnualPlanCTA.vue?vue&type=template&id=4bcff0d2&scoped=true&lang=pug"
import script from "./DaysRemainingAnnualPlanCTA.vue?vue&type=script&lang=js"
export * from "./DaysRemainingAnnualPlanCTA.vue?vue&type=script&lang=js"
import style0 from "./DaysRemainingAnnualPlanCTA.vue?vue&type=style&index=0&id=4bcff0d2&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bcff0d2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconBox: require('/opt/build/repo/components/DataDisplay/IconBox.vue').default})
