

export default {
    name: 'smart-dialog',
    props: {
        open: {
            type: Boolean,
            default: () => false,
        },
        showClose: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
    },
    methods: {
        handleSmartDialogClose() {
            this.$emit('update:open', false);
            this.$emit('close');
            this.$emit('show-selector-onboarding-step');
        }
    }
}
