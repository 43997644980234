
import BasicLayout from '~/layouts/basic'
import gql from 'graphql-tag'
import { mapState, mapActions } from 'pinia'
import { useUiStore } from '~/store/ui'
import { usePopsStore } from '~/store/pops'
import { useIntegrationsStore } from '~/store/integrations'
import { useSiteStore } from '~/store/site'

export default {
    head() {
        return {
            title: 'SalesPop | Pop Events',
        }
    },
    components: {
        BasicLayout
    },
    computed: {
        ...mapState(useUiStore, ['loadingPreflight']),
        ...mapState(useSiteStore, ['currentSite']),
        ...mapState(useIntegrationsStore, ['sourceIntegrationHandles'])
    },
    data: ()=>({
        selectMode: false,
        selectedList: []
    }),
    apollo: {
        $prefetch: false,
        events: {
            query: gql`{
                site {
                    events {
                        id
                        image_url
                        variables
                        created_at
                        site_integration {
                            settings {
                                proof_anonymize
                                proof_anonymize_text
                            }
                            integration {
                                handle
                                name
                                label_name
                            }
                            template {
                                ... on CustomTemplate {
                                    id
                                    body
                                }
                                ... on IntegrationTemplate {
                                    id
                                    body
                                }
                            }
                        }
                    }
                }
            }`,
            update: data => data.site.events
        }
    },
    methods: {
        ...mapActions(usePopsStore, ['deletePopEvent']),
        handleChangeSelected(list){
            this.selectedList = list
        },
        async handleDeleteList(){
            const promises = this.selectedList.map(event => this.deletePopEvent(event.id))
            try{
                const res = await Promise.all(promises)
            } catch(e){
                console.error(e)
            }
            this.$apollo.queries.events.refetch()
            this.selectMode = false
            this.selectedList = []
        },
        getIntegration(handle){
            return this.sourceIntegrationHandles.find(i => i.sourceIntegrationHandles === handle)
        }
    }
}
