
import { mapState } from 'pinia'
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        selectedPlan: {
            type: Object,
            default: {}
        },
    },
    data(){
        return {
            confirmDowngrade: false,
            downgradeFeedback: false,
            loaders: {
                downgrade: false,
            },
        }
    },
    computed: {
        ...mapState(useSiteStore, [
            'currentSite'
        ]),
        ...mapState(usePricingStore, [
            'popupPerks'
        ])
    },
    methods: {
        async changePlan() {
            return this.$router.push('/pricing')
        }
    }
}
