
export default {
    props: {
        discount: {
            type: Object,
            default: () => {
                return {
                    content: {
                        top: '',
                        middle: '',
                    },
                    variables: []
                }
            }
        },
        locale: {
            type: Object,
            default: () => {}
        },
        processingDiscount: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        preventSave() {
            return !this.discount.content.top || this.hasIncorrectTopVariables || this.hasIncorrectMiddleVariables
        },
        hasIncorrectTopVariables() {
            return this.hasIncorrectVariables(this.discount.content.top, this.discount.variables)
        },
        hasIncorrectMiddleVariables() {
            return this.hasIncorrectVariables(this.discount.content.middle, this.discount.variables)
        },
        localeName() {
            return this.locale.local || this.locale.name
        }
    },
    methods: {
        handleEmojiSelect(emoji, position) {
            const textarea = this.$refs[`${position}DiscountContentEditorTextArea`]
            const sentence = textarea.value
            const pos = textarea.selectionStart || 0

            const before = sentence.substr(0, pos)
            const after = sentence.substr(pos, sentence.length)

            this.discount.content[position] = before + emoji.native + after

            this.$nextTick().then(() => {
                textarea.selectionStart = pos + emoji.native.length
            })
        },
        saveDiscount() {
            this.$emit('save', this.discount)
        },
        hasIncorrectVariables(value, availableVariables = []) {
            if(!value) return false

            const parsedVariables = value.match(/{{(.+?)}}/g) || []

            return !parsedVariables.every(variable => availableVariables.includes(variable.replace(/{{|}}/g, '')))
        },
        addVariable(type, variable) {
            this.discount.content[type] += `{{${variable}}}`
        },
    }
}
