
export default {
    props: {
        title: {
            type: String,
        },
        image: {
            type: String,
        },
        price: {
            type: String,
            // default: "$10.99"
        },
        inStock: {
            type: Boolean,
            // default: () => true
        }
    }
}
