
import Color from 'color'
export default {
  props: {
    type: {
      type: String,
      default: 'success'
    },
    title: {
      type: String,
      default: 'Toast title'
    },
    subtitle: {
      type: String,
      default: ''
    },
    bottomMessage: {
        type: String,
        default: 'Our team is already aware of this error, we will try to solve it shortly.'
    },
    ocurrences: {
      type: Number,
      default: 0,
    }
  },
  watch: {
    ocurrences(occurrences, oldOcurrences){
      if(occurrences <= 1 && (occurrences < oldOcurrences)) return
      this.$refs.toast.classList.remove('new-ocurrence')
      // this is the same as element.offsetWidth = element.offsetWidth
      // Is a hack for re-starting the animation
      void this.$refs.toast.offsetWidth
      this.$refs.toast.classList.add('new-ocurrence')
    }
  },
  computed: {
    statusIcon() {
      const iconMap = {
        success: 'uil-check-circle',
        default: 'uil-question-circle'
      }
      return iconMap[this.type] ?? iconMap.default
    },
    cssVars(){
      const colorMap = {
        success: this.$colors.green,
        warning: this.$colors.yellow,
        error: this.$colors.error,
      }

      return {
        '--bg-color': Color(colorMap[this.type]).lightness(95),
        '--font-color': Color(colorMap[this.type])
      }
    },
  },
}
