
import { SkeletonTheme } from 'vue-loading-skeleton'

export default {
    components: {
        SkeletonTheme,
    },
    props: {
        type:{
            type: String,
            default: 'primary'
        },
        size:{
            type: String,
            default: 'medium'
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        loading: {
            type: Boolean,
            default: () => false
        }
    }
}
