import { render, staticRenderFns } from "./UpsellsMetricsActionsCountCards.vue?vue&type=template&id=62b2b04f&scoped=true&lang=pug"
import script from "./UpsellsMetricsActionsCountCards.vue?vue&type=script&lang=js"
export * from "./UpsellsMetricsActionsCountCards.vue?vue&type=script&lang=js"
import style0 from "./UpsellsMetricsActionsCountCards.vue?vue&type=style&index=0&id=62b2b04f&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62b2b04f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KpiCard: require('/opt/build/repo/components/DataDisplay/KpiCard.vue').default})
