import { render, staticRenderFns } from "./discounts.vue?vue&type=template&id=2f2378b2&scoped=true&lang=pug"
import script from "./discounts.vue?vue&type=script&lang=js"
export * from "./discounts.vue?vue&type=script&lang=js"
import style0 from "./discounts.vue?vue&type=style&index=0&id=2f2378b2&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f2378b2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccordionSection: require('/opt/build/repo/components/Editor/AccordionSection.vue').default,ShopifyDiscountSelect: require('/opt/build/repo/components/DataInput/ShopifyDiscountSelect.vue').default,Accordion: require('/opt/build/repo/components/DataDisplay/Accordion.vue').default,Button: require('/opt/build/repo/components/DataInput/Button.vue').default,DiscountContentEditor: require('/opt/build/repo/components/Editor/DiscountContentEditor.vue').default})
