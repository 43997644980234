
import { HorizontalBar , mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
import merge from 'lodash/merge'

export default {
    extends: HorizontalBar,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    computed: {
        chartOptions() {
            const defaultOptions = {
                maintainAspectRatio: false,
                responsive: true,
                defaultFontFamily: "'CircularStd', 'helvetica', 'sans-serif'",
                layout: {
                    padding: 30,
                },
                legend: {
                    display: false
                },
                tooltips: {
                    titleFontFamily: "'CircularStd', 'helvetica', 'sans-serif'",
                    bodyFontFamily: "'CircularStd', 'helvetica', 'sans-serif'"
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            min: 0,
                            fontColor: this.$colors.neutral[400],
                            fontFamily: "'CircularStd', 'helvetica', 'sans-serif'"
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: '#e9eff4'
                        },
                        ticks: {
                            min: 0,
                            fontColor: this.$colors.neutral[400],
                            fontFamily: "'CircularStd', 'helvetica', 'sans-serif'"
                        }
                    }]
                }
            }
            return merge(defaultOptions, this.options)
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.chartOptions)
    }
}
