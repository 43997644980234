
export default {
  data() {
    return {
      screen: this.mode,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'desktop'
    },
    responsiveToggle: {
      type: Boolean,
      default: () => true
    },
    upsellAddToCartProps: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    isMobileScreen() {
      return this.screen === 'mobile'
    },
    upsellsGeneralSettingsExample() {
      return {
        desktop_enabled: true,
        tablet_enabled: true,
        mobile_enabled: true
      }
    }
  },
  methods: {
    changeScreen(screen) {
      this.screen = screen
    }
  }
}
