
import BasicLayout from '~/layouts/basic'
import { mapState, mapActions } from 'pinia'
import { useUiStore } from '~/store/ui'
import { useUpsellsSiteEventsStore } from '~/store/upsells/site_events.js'

export default {
  head() {
    return {
      title: 'SalesPop | Upsell History',
    }
  },
  data: () => ({
    currentPage: 1,
  }),
  async mounted() {
    await this.loadUpsellsSiteEventsHistory(this.currentPage)
    this.addScrollEventListener()
  },
  components: {
    BasicLayout
  },
  computed: {
    ...mapState(useUiStore, ['loadingPreflight']),
    ...mapState(useUpsellsSiteEventsStore, ['upsellsSiteEvents', 'loading', 'reachedBottom'])
  },
  methods: {
    ...mapActions(useUpsellsSiteEventsStore, ['loadUpsellsSiteEventsHistory']),
    addScrollEventListener() {
      const listElm = document.querySelector('.content-layout');
      listElm.addEventListener('scroll', e => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight * 0.8) {
          if (!this.reachedBottom && !this.loading) {
            this.currentPage += 1;
            this.loadUpsellsSiteEventsHistory(this.currentPage)
          }
        }
      });
    }
  }
}
