import { render, staticRenderFns } from "./PopBackgroundImageForm.vue?vue&type=template&id=45392048&scoped=true&lang=pug"
import script from "./PopBackgroundImageForm.vue?vue&type=script&lang=js"
export * from "./PopBackgroundImageForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45392048",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageUpload: require('/opt/build/repo/components/DataInput/ImageUpload.vue').default})
