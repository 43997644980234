import { render, staticRenderFns } from "./PlanChangeModal.vue?vue&type=template&id=b9b7ec8c&scoped=true&lang=pug"
import script from "./PlanChangeModal.vue?vue&type=script&lang=js"
export * from "./PlanChangeModal.vue?vue&type=script&lang=js"
import style0 from "./PlanChangeModal.vue?vue&type=style&index=0&id=b9b7ec8c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9b7ec8c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PerksGrid: require('/opt/build/repo/components/business/PerksGrid.vue').default,Button: require('/opt/build/repo/components/DataInput/Button.vue').default,SmartDialog: require('/opt/build/repo/components/Layout/SmartDialog.vue').default})
