
import numeral from 'numeral'

export default {
    props: {
        unlimited: {
            type: Boolean,
            default: () => false
        },
        max: {
            type: Number,
        },
        extra: {
          type: Number,
        },
        actual: {
            type: Number,
        },
        limitReached: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
    },
    methods: { numeral },
    computed: {
        percentage(){
            const { actual, max, extra } = this
            return numeral(
                actual / (max + extra) * 100
            ).format('0,0')
        }
    }
}
