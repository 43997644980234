import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f7a6e474&scoped=true&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=f7a6e474&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7a6e474",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlanChangeModal: require('/opt/build/repo/components/business/PlanChangeModal.vue').default,Button: require('/opt/build/repo/components/DataInput/Button.vue').default,SmartDialog: require('/opt/build/repo/components/Layout/SmartDialog.vue').default,BinarySwitch: require('/opt/build/repo/components/DataInput/BinarySwitch.vue').default,UpsellAddToCartResponsivePreview: require('/opt/build/repo/components/business/UpsellAddToCartResponsivePreview.vue').default})
