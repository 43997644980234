import { render, staticRenderFns } from "./general.vue?vue&type=template&id=6106301e&scoped=true&lang=pug"
import script from "./general.vue?vue&type=script&lang=js"
export * from "./general.vue?vue&type=script&lang=js"
import style0 from "./general.vue?vue&type=style&index=0&id=6106301e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6106301e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaveOrCancelPopSettings: require('/opt/build/repo/components/Editor/SaveOrCancelPopSettings.vue').default,SmartDialog: require('/opt/build/repo/components/Layout/SmartDialog.vue').default,DrawerButton: require('/opt/build/repo/components/Layout/Drawer/DrawerButton.vue').default,IconBox: require('/opt/build/repo/components/DataDisplay/IconBox.vue').default,BrandingForm: require('/opt/build/repo/components/Forms/BrandingForm.vue').default,Card: require('/opt/build/repo/components/Layout/Card.vue').default,ChooseLanguageForm: require('/opt/build/repo/components/Forms/ChooseLanguageForm.vue').default,ChooseDevicesForm: require('/opt/build/repo/components/Forms/ChooseDevicesForm.vue').default,ChoosePopPositionForm: require('/opt/build/repo/components/Forms/ChoosePopPositionForm.vue').default,PopTimingForm: require('/opt/build/repo/components/Forms/PopTimingForm.vue').default,Tag: require('/opt/build/repo/components/DataDisplay/Tag.vue').default,ChoosePopSizeForm: require('/opt/build/repo/components/Forms/ChoosePopSizeForm.vue').default,PopUniqSessionsPerVisitorForm: require('/opt/build/repo/components/Forms/PopUniqSessionsPerVisitorForm.vue').default,EmptyMediaItem: require('/opt/build/repo/components/EmptyState/EmptyMediaItem.vue').default})
