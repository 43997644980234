import { render, staticRenderFns } from "./metrics.vue?vue&type=template&id=c03aaa38&scoped=true&lang=pug"
import script from "./metrics.vue?vue&type=script&lang=js"
export * from "./metrics.vue?vue&type=script&lang=js"
import style0 from "./metrics.vue?vue&type=style&index=0&id=c03aaa38&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c03aaa38",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Card: require('/opt/build/repo/components/Layout/Card.vue').default,Tag: require('/opt/build/repo/components/DataDisplay/Tag.vue').default,PopsMetricsActionsCountCards: require('/opt/build/repo/components/Pops/Metrics/PopsMetricsActionsCountCards.vue').default,PopsMetricsTemplatesActionsPieChart: require('/opt/build/repo/components/Pops/Metrics/PopsMetricsTemplatesActionsPieChart.vue').default,PopsMetricsConversionAggregationsChart: require('/opt/build/repo/components/Pops/Metrics/PopsMetricsConversionAggregationsChart.vue').default,PopsMetricsConversionByPopTypesAggregationsChart: require('/opt/build/repo/components/Pops/Metrics/PopsMetricsConversionByPopTypesAggregationsChart.vue').default,PopsMetricsTemplatesActionsBarChart: require('/opt/build/repo/components/Pops/Metrics/PopsMetricsTemplatesActionsBarChart.vue').default,PopsMetricsDiscountCodeAggregationsChart: require('/opt/build/repo/components/Pops/Metrics/PopsMetricsDiscountCodeAggregationsChart.vue').default})
