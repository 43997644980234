
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'

import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import Link from '@tiptap/extension-link'
import Bold from '@tiptap/extension-bold'
import Underline from '@tiptap/extension-underline'
import Italic from '@tiptap/extension-italic'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import Strike from '@tiptap/extension-strike'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
import CharacterCount from '@tiptap/extension-character-count'
import History from '@tiptap/extension-history'
import TextAlign from '@tiptap/extension-text-align'
import Typography from '@tiptap/extension-typography'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'

export default {
  components: {
    EditorContent,
    BubbleMenu,
    vDropdown: () => import('v-dropdown')
  },
  props: {
      editorOptions: {
          type: Object
      },
      value: {
        type: String,
        default: '',
      },
      limit: {
        type: Number,
        default: 280
      }
  },
  data() {
    return {
      editor: null,
      linkInputMode: false,
      currentColor: null,
    }
  },
  methods: {
    setLink() {
      const url = window.prompt('URL')

      if(!url?.length) return

      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    handleEmojiSelect(emoji){
        this.editor.chain().focus().insertContent(emoji.native).run()
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }
      this.editor.commands.setContent(this.value, false)
    },
  },
  computed: {
    content: {
      get(){
        return this.value
      },
      set(content){
        this.$emit('input', content)
      }
    },
    colors(){
      return [
          '#55efc4',
          '#81ecec',
          '#74b9ff',
          '#a29bfe',
          '#ffeaa7',
          '#fab1a0',
          '#ff7675',
          '#fd79a8',
          '#dfe6e9',
          '#636e72',
          '#ffffff',
          '#00b894',
          '#00cec9',
          '#0984e3',
          '#6c5ce7',
          '#fdcb6e',
          '#e17055',
          '#d63031',
          '#e84393',
          '#b2bec3',
          '#2d3436',
          '#f2f6fa'
      ]
  }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        Heading.configure({
          levels: [1, 2],
        }),
        Link.configure({
          openOnClick: false,

        }),
        Bold,
        Italic,
        // Highlight.configure({ multicolor: true }),
        BulletList,
        OrderedList,
        ListItem,
        Strike,
        Superscript,
        Subscript,
        Underline,
        CharacterCount.configure({
          limit: this.limit,
        }),
        History,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Typography,
        TextStyle,
        Color,
      ],
      content: this.value,
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      },
      onSelectionUpdate: ({editor}) => {
        for(let color of this.colors) {
          if(editor.isActive('textStyle', { color })) {
            console.log(color, editor.isActive('textStyle', { color }))
            this.currentColor = color
            return
          }
        }
        this.currentColor = null
      },
      ...this.editorOptions
    })
  },
  beforeDestroy() {
    this.editor?.destroy()
  },
}
