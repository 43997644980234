
import Color from 'color';

export default {
    props: {
        value: {
            type: Boolean,
            default: () => false,
        },
        text: {
            type: String,
            default: 'text',
        },
        color: {
            type: String,
            default: '#6F58FF'
        },
        aspect: {
            type: String, 
            default: 'aspect-ratio-square'
        },
        disabled: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        cssVars(){
            const {value} = this
            return {
                '--color': value ? this.color : this.$colors.neutral[600],
                '--bg-color': value ? Color(this.color).fade(0.9) : this.$colors.base?.[300],
            }
        }
    }
}
