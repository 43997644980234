
import BasicLayout from '~/layouts/basic'
import { isEqual, cloneDeep } from 'lodash'
import {mapActions, mapState, mapWritableState} from 'pinia'
import { useUiStore } from '~/store/ui'
import { useUpsellsGeneralSettingsStore } from '~/store/upsells/general_settings'
import { useSiteStore } from '~/store/site'
import { useBaseOnboardingStore } from '~/store/onboardings/baseOnboarding'

export default {
    head() {
        return {
            title: 'SalesPop | Pop Rules & Settings',
        }
    },
    components: {
        BasicLayout
    },
    data(){
        return {
            visibleSection: null,
            originalSite: null,
            confirmLeave: false,
            leavePath: null,
            isDirty: false,
        }
    },
    computed: {
        ...mapState(useSiteStore, {
            loadingCurrentSite: 'loading',
        }),
        ...mapState(useUpsellsGeneralSettingsStore, [
            'menuButtons',
            'devices',
            'activeDevices',
            'showPoweredBy',
            'loading',
        ]),
      ...mapState(useBaseOnboardingStore, ['currentOnboarding']),
      ...mapWritableState(useUiStore, [
            'loadingPreflight',
        ]),
        ...mapState(useUpsellsGeneralSettingsStore, [
          'settings',
        ]),
        loadingData() {
          this.loadingCurrentSite && this.loading
        },
        activeDevices:{
            get(){
                return this.devices
                    .filter(({settingsKey}) => {
                        return this.settings[settingsKey]
                    })
                    .map(({text}) => text)
            },
            set(newDevices){
                this.setNewDevices(newDevices)
            }
        },
    },
    watch: {
        loadingPreflight: {
            immediate: true,
            async handler(loading){
                if(loading) return
                if(this.$route.query.scrollTo) {
                    setTimeout(() => this.scrollSection({}, this.$route.query.scrollTo), 1200)
                }
            }
        },
        settings: {
            deep: true,
            handler(){
                this.isDirty = !isEqual(this.settings, this.originalSettings)
            }
        },
    },
    async mounted(){
        await this.loadCurrentSite()
        await this.loadGeneralSettings()
        this.originalSettings = cloneDeep(this.settings)
    },
    beforeRouteLeave(to, from, next){
        if(this.isDirty && !this.currentOnboarding) {
            this.leavePath = to.path
            this.confirmLeave = true

            return next(false)
        }

        next()
    },
    methods: {
        ...mapActions(useSiteStore, [
            'loadCurrentSite'
        ]),
        ...mapActions(useUpsellsGeneralSettingsStore, [
            'loadGeneralSettings',
            'saveGeneralSettings',
            'setNewDevices',
        ]),
        scrollSection(event, slug){
            event?.preventDefault?.()
            event?.stopPropagation?.()
            const el = this.$refs?.[slug]
            if(!el) return
            el.scrollIntoView({behavior: "smooth"})
            el.classList.add('scroll-highligth')

            setTimeout(()=>{
                el.classList.remove('scroll-highligth')
            }, 700)
        },
        async handleSave({ leave }) {
            if(!this.isDirty) return

            await this.saveGeneralSettings()

            this.isDirty = false

            if(leave) { this.$router.push(this.leavePath) }

            this.originalSettings = cloneDeep(this.settings)
        },
        handleLeave() {
            this.isDirty = false
            this.$router.push(this.leavePath)
        }
    },
}
