
export default {
    data: ()=>({
        active: 'desktop',
        screens: [{
            icon: 'desktop',
            name: 'desktop'
        },{
            icon: 'mobile-android',
            name: 'mobile'
        }]
    }),
    methods: {
        toggle(screen){
            this.active = screen
            this.$emit('toggle', screen)
        }
    }
}
