
export default {
    components: {
        DesktopTopRightIcon: ()=> import('~/static/icons/settings/desktop-tr.svg'),
        DesktopTopLeftIcon: ()=> import('~/static/icons/settings/desktop-tl.svg'),
        DesktopBottomRightIcon: ()=> import('~/static/icons/settings/desktop-br.svg'),
        DesktopBottomLeftIcon: ()=> import('~/static/icons/settings/desktop-bl.svg'),
        MobileTopIcon: ()=> import('~/static/icons/settings/mobile-top.svg'),
        MobileBottomIcon: ()=> import('~/static/icons/settings/mobile-bottom.svg'),
    },
    props: {
        desktop: {
            type: String,
            default: ''
        },
        mobile: {
            type: String, 
            default: ''
        }
    },
    computed: {
        desktopPositions(){
            return [{
                text: 'Top Right',
                icon: 'DesktopTopRightIcon'
            },{
                text: 'Top Left',
                icon: 'DesktopTopLeftIcon'
            },{
                text: 'Bottom Right',
                icon: 'DesktopBottomRightIcon'
            },{
                text: 'Bottom Left',
                icon: 'DesktopBottomLeftIcon'
            },]
        },
        mobilePositions(){
            return [{
                text: 'Top',
                icon: 'MobileTopIcon'
            },{
                text: 'Bottom',
                icon: 'MobileBottomIcon'
            }]
        }
    },
    methods: {
        toggleDesktopPosition(position) {
            this.$emit('update:desktop', position)
            this.$emit('change')
        },
        toggleMobilePosition(position) {
            this.$emit('update:mobile', position)
            this.$emit('change')
        }
    }
}
