
export default {
    props: {
        reason: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showCopy: false
        }
    },
    methods: {
        toggle() {
            this.showCopy = !this.showCopy
            this.$emit('click', this.reason)
        }
    }
}
