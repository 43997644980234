
import { mapState } from 'pinia'
import { useSiteStore } from '~/store/site'

export default {
    head(){
        return {
            script: [
                { src: "https://js.csvbox.io/script.js", callback: this.initImporter }
            ]
        }
    },
    data(){
        return {
            importer: null,
            csvFile: null,
            error: null,
            csvBoxLicenseKey: process.env.CSVBOX_LICENSE_KEY,
        }
    },
    computed: {
        ...mapState(useSiteStore, ['uniqueId']),
        uploadDisabled() {
            return this.files?.length === 0
        }
    },
    methods:{
        initImporter(){
            this.importer = new CSVBoxImporter(this.csvBoxLicenseKey, {}, this.onSuccess);
            this.importer.setUser({
                unique_id: this.uniqueId,
                user_id: this.uniqueId
            });
        },
        downloadTemplateFile(){
            const routeData = this.$router.resolve('/public/SalesPOP Reviews CSV Template.csv');
            window.open(routeData.href, '_blank');
        },
        importExternal(){
            window.open(
                `https://app.csvbox.io/upload/${this.csvBoxLicenseKey}?user_id=${this.uniqueId}`,
                '_blank'
            )
        },
        removeFile() {
            this.csvFile = null
            // this.proof_background_image_url = null
            //TODO: Delete image from database
        },
        onSuccess(e) {
            this.$toasts.add({
                title: 'File imported!',
                subtitle: 'Your reviews are being processed!',
                e,
            })
            // this.proof_background_image_url = e.cdnUrl
        },
        onError(e) {
            this.$toasts.add({
                type:"error",
                title: 'File import error!',
            })
            console.error(e)
        },
    }
}
