
import { mapState } from 'pinia'
import { useIntegrationsStore } from '~/store/integrations'
export default {
    props: {
        activePops: {
            type: Array,
            default: () => []
        }
    },
    computed:{
        ...mapState(useIntegrationsStore, ['sourceIntegrationHandles']),
        notProIntegrations(){
            return this.sourceIntegrationHandles.filter(i => !i.pro)
        }
    },
    methods: {
        togglePopType(popType){
            let {activePops} = this
            if(activePops.includes(popType.integrationHandle)){
                this.$emit('update:activePops', activePops.filter(p => p !== popType.integrationHandle))
            } else {
                this.$emit('update:activePops', [...activePops, popType.integrationHandle])
            }
        }
    }
}
