

export default {
    props: {
        active: {
            type: Array,
            default: []
        },
        devices: {
          type: Array,
          default: []
        }
    },
    methods: {
        toggleDevice(device){
            const active = new Set(this.active)
            if(active.has(device)){
                active.delete(device)
                this.$emit('update:active', Array.from(active))
                this.$emit('change', Array.from(active))
            } else {
                active.add(device)
                this.$emit('update:active', Array.from(active))
                this.$emit('change', Array.from(active))
            }
        }
    },
}
