
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src"
import data from "emoji-mart-vue-fast/data/all.json"
import "emoji-mart-vue-fast/css/emoji-mart.css"

let emojiIndex = new EmojiIndex(data);

export default {
    props: {
        append: {
            type: String,
        }
    },
    components: {
        Picker,
        'v-dropdown': () => import('v-dropdown')
    },
    data() {
        return {
            emojiIndex,
            clickingTrigger: false,
        }
    },
    methods: {
        emojiSelect(emoji) {
            this.$refs.dropdown.visible()
            if(this.append){
                this.$emit('update:append', this.append + emoji.native)
            }
            this.$emit('select', emoji)
        }
    }
}
