
import { mapState } from 'pinia'
import { requiredUnless } from 'vuelidate/lib/validators'
import { mapWritableState, mapActions } from 'pinia'
import { useUiStore } from '~/store/ui'
import { useIntegrationsStore } from '~/store/integrations'
import { useTemplatesStore } from '~/store/templates'
import { useSiteStore } from '~/store/site'
import { usePricingStore } from '~/store/pricing'

function hasCorrectVariables(value, availableVars = []) {
    if(!value) return true
    const liquidVars = value.match(/{{(.+?)}}/g)
    const diff = liquidVars?.filter(l => !availableVars?.includes(l)) ?? []
    return diff.length === 0
}

export default {
    async mounted(){
        if(this.templates?.length === 0) await this.loadTemplates()
    },
    validations: {
        top: {
            required: requiredUnless(function() {
                return this.sectionIsDisabled('top')
            }),
            hasCorrectVariables(value) {
                return hasCorrectVariables(value, this.pop.topLiquidVars)
            }
        },
        middle: {
            required: requiredUnless(function() {
                return this.sectionIsDisabled('middle')
            }),
            hasCorrectVariables(value) {
                return hasCorrectVariables(value, this.pop.middleLiquidVars)
            }
        }
    },
    data(){
        return {
            top: '',
            middle: '',
            isFreeCustomer: false,
        }
    },
    computed: {
        ...mapState(useSiteStore, ['parsedLocale']),
        ...mapState(useTemplatesStore, ['templates', 'bodyObject']),
        ...mapState(usePricingStore, ['plan']),
        ...mapWritableState(useUiStore, ['editingPopHandler', 'planChangeModal']),
        ...mapWritableState(useIntegrationsStore, ['popTypes', 'currentIntegration']),
        template() {
            return this.templates?.find(item =>
                item.handle === this.editingPopHandler
            )
        },
        pop(){
            return this.popTypes.find(type =>
                type.integrationHandle === this.editingPopHandler
            ) || {}
        },
        stringBody(){
            return `<top>${this.top}</top><middle>${this.middle}</middle>`
        },
        disabledSections(){
            return this.currentIntegration?.disabledSections
        }
    },
    watch: {
        stringBody(newString) {
            this.SET_CURRENT_TEMPLATE_BODY(newString)
        },
        bodyObject:{
            immediate: true,
            handler(body){
                this.top = body.top
                this.middle = body.middle
            },
        },
        '$v.$invalid'(isInvalid){
            this.$emit('valid', !isInvalid)
        }
    },
    methods: {
        ...mapActions(useTemplatesStore, ['loadTemplates', 'saveCurrentTemplate', 'SET_CURRENT_TEMPLATE_BODY']),
        addLiquidVar(what, liquidVar) {
            if (what === 'top') {
                if (!this.top.includes(liquidVar)) {
                    this.top = `${this.top} ${liquidVar}`
                }
            } else {
                if (!this.middle.includes(liquidVar)) {
                    this.middle = `${this.middle} ${liquidVar}`
                }
            }
        },
        cleanLiquidString(liquid){
            return liquid.replace('{{', '').replace('}}', '')
        },
        sectionIsDisabled(section){
            if(!this.disabledSections) return false
            return this.disabledSections.includes(section)
        }
    },
}
