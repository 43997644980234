
import numeral from "numeral";
import { mapState, mapActions } from 'pinia'
import { usePopMetricsStore } from "~/store/pops/metrics";
import PopType from "~/components/Forms/PopType.vue";
import {useIntegrationsStore} from "~/store/integrations";

export default {
    props: {
        periodLabel: String,
        period: Object
    },
    data() {
        return {
            omittedPopTypes: [],
            dataMap: {},
            conversions: {},
            chartOptions: {
                scales: {
                    yAxes: [
                        {
                            gridLines: { display: false },
                            ticks: { display: true, min: 0 },
                        }
                    ]
                },
                cornerRadius: 7,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let label = data.datasets[tooltipItem.datasetIndex].label

                            let count = this.formattedNumericValue(
                                data.datasets[tooltipItem.datasetIndex].count[tooltipItem.index],
                                '0,0'
                            )

                            return label ? `${label} (Total Count: ${count}):` : ''
                        },
                        afterLabel: (tooltipItem, data) => {
                            let conversions = this.conversions[data.datasets[tooltipItem.datasetIndex].stack][tooltipItem.index]

                            let value = []

                            if(conversions) {
                                Object.entries(conversions).forEach(([conversion, conversionData]) => {
                                    value.push(`${conversion} (Count: ${conversionData.count}, Amount: ${conversionData.amount}) `)
                                })
                            }

                            return conversions ? value : ''
                        }
                    }
                }
            }
        }
    },
    watch: {
        period: {
            deep: true,
            immediate: true,
            handler(value) {
                if(!value) return

                this.omittedPopTypes = []
                this.loadConversionByTypeAggregations({ startDate: value.from, endDate: value.to })
            }
        }
    },
    methods: {
        ...mapActions(usePopMetricsStore, ['loadConversionByTypeAggregations', 'colorFromMD5']),
        handleFiltering(popType) {
            if(this.omittedPopTypes.includes(popType)) {
                this.omittedPopTypes = this.omittedPopTypes.filter(entry => entry !== popType)
            } else {
                this.omittedPopTypes.push(popType)
            }
        },
        formattedNumericValue(value, format = '0,0.00') {
            return numeral(value).format(format)
        },
        formattedPopTypeTitle(popType) {
            return this.popTypes.find(type => {
                return type.integrationHandle === popType
            }).title
        },
    },
    computed: {
        ...mapState(usePopMetricsStore, ['conversionByTypeAggregations', 'loadingConversionByTypeAggregation', 'granularityData']),
        ...mapState(useIntegrationsStore, ['popTypes']),
        conversionTypesChartData() {
            let datasets = []
            let labels = []
            this.conversions = {}
            this.dataMap = {}

            if(this.popTypesExist) {
                let popTypes = this.conversionByTypeAggregations.pop_types

                popTypes.forEach((popType) => {
                    let color = this.colorFromMD5(popType)

                    this.conversions[popType] = []

                    this.dataMap[popType] = {
                        color: color,
                        data: [],
                        count: [],
                        total_count: 0,
                        pop_type: popType,
                    }
                })

                Object.entries(this.conversionByTypeAggregations.aggregations).forEach(([date, data]) => {
                    labels.push(this.$moment.utc(date).format(this.conversionsDateFormat))

                    Object.keys(this.dataMap).forEach((popType) => {
                        let typesCount = null;

                        if (data[popType]) {
                            typesCount = Object.keys(data[popType]).reduce((sum, conversion) => {
                                sum += data[popType][conversion].count || null
                                return sum
                            }, null)
                        }

                        this.conversions[popType].push(data[popType])
                        this.dataMap[popType].total_count += typesCount
                        this.dataMap[popType].count.push(typesCount)
                        this.dataMap[popType].data.push(typesCount)
                    })
                })

                let filteredData = Object.keys(this.dataMap).reduce((data, popType) => {
                    if(!this.omittedPopTypes.includes(popType)) {
                        data[popType] = this.dataMap[popType]
                    }

                    return data
                }, {})

                Object.keys(filteredData).forEach((key) => {
                    let label = this.formattedPopTypeTitle(key)

                    datasets.push({
                        label: label,
                        backgroundColor: this.dataMap[key]?.color,
                        count: this.dataMap[key]?.count,
                        data: this.dataMap[key]?.data,
                        stack: key
                    })
                })
            }

            return { labels, datasets, period: this.period }
        },
        popTypesExist() {
            return this.conversionByTypeAggregations.pop_types.length
        },
        totalConversionsCount() {
            if(!this.popTypesExist) return 0
            return Object.values(this.dataMap).reduce((total, entry) => total + entry.total_count, 0)
        },
        conversionsDateFormat() {
            return this.granularityData.dateFormats[this.conversionByTypeAggregations.granularity]
        },
    },
}
