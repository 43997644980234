import { render, staticRenderFns } from "./ManualReviewForm.vue?vue&type=template&id=245f336f&scoped=true&lang=pug"
import script from "./ManualReviewForm.vue?vue&type=script&lang=js"
export * from "./ManualReviewForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245f336f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RatingStars: require('/opt/build/repo/components/DataInput/RatingStars.vue').default,ProductSelect: require('/opt/build/repo/components/DataInput/ProductSelect.vue').default,Button: require('/opt/build/repo/components/DataInput/Button.vue').default})
