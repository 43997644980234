
import { mapWritableState, mapState, mapActions } from 'pinia'
import { useUiStore } from '~/store/ui'
import { useIntegrationsStore } from '~/store/integrations'
import { usePricingStore } from '~/store/pricing'
import { useTemplatesStore } from '~/store/templates'
import { useSiteStore } from '~/store/site'

export default {
    data(){
        return {
            confirmLeave: false,
        }
    },
    mounted(){
        if(
            this.$route.query.pop
            // Prevent access to pro integrations thru query params
            && !this.integrationHandleMap[this.$route.query.pop]?.pro
            && !this.$isPro()
        ) {
            useUiStore().setTemplateHandler(this.$route.query.pop)
        } else {
            const sidebarOption = this.sidebarOptions.find(o => o.route === this.$route.path)
            if(!sidebarOption?.defaultHandle) return
            useUiStore().setTemplateHandler(sidebarOption.defaultHandle)
        }
    },
    watch: {
        'currentSite.source'(source){
            useUiStore().setTemplateHandler(this.$source() === 'shopify' ? 'shopify_storefront_add_to_cart' : 'wix_api_add_to_cart')
        },
        loadingPreflight: {
            immediate: true,
            async handler(loading){
                if(loading) return

                await this.$track('page_view/pop_editor')
            }
        }
    },
    computed: {
        ...mapState(useSiteStore, ['currentSite']),
        ...mapState(usePricingStore, ['plan']),
        ...mapState(useUiStore, ['loadingPreflight', 'planChangeModal']),
        ...mapState(useIntegrationsStore, ['integrationHandleMap']),
        ...mapState(useTemplatesStore, [
            'canSavePro',
            'allFormsAreValid',
            'loading',
            'currentTemplate',
            'currentTemplateDifference'
        ]),
        canSave(){
          return this.canSavePro && this.allFormsAreValid
        },
        sidebarOptions(){
            const isDirty = Object.keys(this.currentTemplateDifference)?.length > 0
            return [{
                text: 'Go Back',
                icon: 'arrow-left',
                route: isDirty ? '' : '/pops/rules-and-settings',
                clickAction: () => {
                    this.confirmLeave = true
                    this.leaveRoute = '/pops/rules-and-settings'
                },
                id: "popsRulesAndSettingGoBackMenuButton",
            },{
                text: 'Reviews',
                icon: 'star',
                route: '/pops/rules-and-settings/reviews',
                condition: this.currentTemplate?.handle === 'shopify_review',
                defaultHandle: 'shopify_review'
            },{
                text: 'Settings',
                icon: 'sliders-v-alt',
                condition: this.currentTemplate?.handle !== 'shopify_discount',
                route: '/pops/rules-and-settings/settings'
            }, {
                text: 'Discounts',
                icon: 'percentage',
                route: '/pops/rules-and-settings/discounts',
                condition: this.currentTemplate?.handle === 'shopify_discount',
                defaultHandle: 'shopify_discount'
            }, {
                text: 'Templates',
                icon: 'apps',
                route: '/pops/rules-and-settings/media-templates',
                condition: this.currentTemplate?.handle === 'nudge',
                defaultHandle: 'nudge'
            },{
                text: 'Design',
                icon: 'brush-alt',
                route: '/pops/rules-and-settings/design',
                condition: this.currentTemplate?.handle !== 'nudge',
                id: "popsRulesAndSettingDesignMenuButton"
            },{
                text: 'Themes',
                icon: 'comment-image',
                route: '/pops/rules-and-settings/themes',
                condition: this.currentTemplate?.handle !== 'nudge',
                id: "popsRulesAndSettingThemesMenuButton"
            },{
                text: 'Pages',
                icon: 'border-inner',
                route: '/pops/rules-and-settings/pages',
                id: "popsRulesAndSettingPagesMenuButton"
            }]
        }
    },
    methods: {
        ...mapActions(useTemplatesStore, ['saveCurrentTemplate']),
        async handleSave({leave}){
            if(!this.canSavePro || !this.allFormsAreValid) return true
            try {
                await this.saveCurrentTemplate()
                if(leave) this.$router.push(this.leaveRoute)
            } catch (e) {
                console.error(e)
            }
        },
        handlefunctionClick(option){
            option.clickAction?.()
        }
    },
}
