import { render, staticRenderFns } from "./ReviewCapForm.vue?vue&type=template&id=50e41f22&scoped=true&lang=pug"
import script from "./ReviewCapForm.vue?vue&type=script&lang=js"
export * from "./ReviewCapForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e41f22",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RatingStars: require('/opt/build/repo/components/DataInput/RatingStars.vue').default})
