
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'
import { mapActions, mapState } from 'pinia'

export default {
    components: {
        'v-dropdown': () => import('v-dropdown')
    },
    props: {
        size: {
            type: String,
            default: () => 'compact',
        },
        hideAppEnabledToggle: {
            type: Boolean,
            default: () => false,
        },
        loading: {
            type: Boolean,
            default: () => false,
        }
    },
    data(){
        return {
            notificationsOpen: false,
            profileOpen: false,
            loadingAppEnable: false,
        }
    },
    computed: {
        ...mapState(useSiteStore, [
            'currentSite',
        ]),
        ...mapState(usePricingStore, ['limitReached']),
        proofVisible: {
            get(){
                return this.currentSite.proof_visible && !this.limitReached && this.currentSite.billing_active
            },
            set(visible){
                this.currentSite.proof_visible = visible
            }
        },
        shouldDisableVisibility() {
            if(!this.currentSite.billing_active) return true
            if(this.currentSite.utils.with_pricing_plan) return this.limitReached
            return this.limitReached && this.currentSite.plan === 'free'
        },
        buttonTooltip() {
            let tooltip = ''

            if (this.shouldDisableVisibility) {
                if(!this.currentSite.billing_active) {
                    tooltip += `Your subscription is in inactive status. Activate subscription to enable pops.`
                }

                if(this.currentSite.utils.with_pricing_plan && this.limitReached) {
                    tooltip += ' Session limit reached, upgrade to enable pops.'
                }

                if(!this.currentSite.utils.with_pricing_plan && this.limitReached && this.currentSite.plan === 'free') {
                    tooltip += ' Session limit reached, upgrade to enable pops.'
                }
            } else {
                if (this.currentSite.proof_visible) {
                    tooltip += `This only turns your pop notifications off and does not cancel your subscription.`
                }
            }

            return tooltip
        }
    },
    methods: {
        ...mapActions(useSiteStore, ['saveSiteSettings']),
        handleProfileClick(e){
            if(this.$mq === 'mobile') {
                e.stopPropagation()
                this.$router.push('/profile/subscription')
            }
            else {
                this.profileOpen = true
            }
        },
        changeSite(token){
            location.href = `/auth/?token=${token}`
        },
        handlePopsVisible(){
            this.loadingAppEnable = true
            this.saveSiteSettings()
            this.loadingAppEnable = false
        }
    }
}
