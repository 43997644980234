
import { mapState } from 'pinia'
import { usePricingStore } from '~/store/pricing'

export default {
    data(){
        return {
            showPlanModal: false,
        }
    },
    props: {
        text: {
            type: String,
            default: 'This is a pro feature, upgrade to use it.'
        }
    },
    computed: {
        ...mapState(usePricingStore, ['plan']),
    },
}
