import { render, staticRenderFns } from "./DowngradePricingPlanAtNotification.vue?vue&type=template&id=0906dbf7&scoped=true&lang=pug"
import script from "./DowngradePricingPlanAtNotification.vue?vue&type=script&lang=js"
export * from "./DowngradePricingPlanAtNotification.vue?vue&type=script&lang=js"
import style0 from "./DowngradePricingPlanAtNotification.vue?vue&type=style&index=0&id=0906dbf7&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0906dbf7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconBox: require('/opt/build/repo/components/DataDisplay/IconBox.vue').default})
