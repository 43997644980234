
export default {
    props: {
        message: {
            type: String,
            default: 'Mi message'
        },
        closable: {
            type: Boolean,
            default: () => true
        },
        open: {
            type: [Boolean, Number],
            default: () => false
        },
        pages: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        handleClose(){
            if(this.closable) {
                $emit('update:open', false)
            }
        }
    },
    computed: {
        showInThisPage(){
            if(this.pages.length === 0) return true
            return this.pages.includes(this.$route.path)
        }
    }
}
