import { render, staticRenderFns } from "./media-templates.vue?vue&type=template&id=96a3f76a&scoped=true&lang=pug"
import script from "./media-templates.vue?vue&type=script&lang=js"
export * from "./media-templates.vue?vue&type=script&lang=js"
import style0 from "./media-templates.vue?vue&type=style&index=0&id=96a3f76a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96a3f76a",
  null
  
)

export default component.exports