import { render, staticRenderFns } from "./PopContent.vue?vue&type=template&id=a06d58ba&scoped=true&lang=pug"
import script from "./PopContent.vue?vue&type=script&lang=js"
export * from "./PopContent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a06d58ba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DesignHuddleModal: require('/opt/build/repo/components/business/DesignHuddleModal.vue').default,TipTap: require('/opt/build/repo/components/DataInput/TipTap.vue').default,Button: require('/opt/build/repo/components/DataInput/Button.vue').default,BinarySwitch: require('/opt/build/repo/components/DataInput/BinarySwitch.vue').default,IconBox: require('/opt/build/repo/components/DataDisplay/IconBox.vue').default,PopColorsForm: require('/opt/build/repo/components/Forms/PopColorsForm.vue').default,ImageUpload: require('/opt/build/repo/components/DataInput/ImageUpload.vue').default})
