
import EditorContentLayout from '~/layouts/editorContent.vue'
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useEditorStore } from '~/store/editor'

import merge from 'deepmerge'
import { useUiStore } from '~/store/ui'
import { useThemesStore } from '~/store/themes'
import { usePopsStore } from '~/store/pops'
import { useTemplatesStore } from '~/store/templates'
import { useBaseOnboardingStore } from "~/store/onboardings/baseOnboarding";

export default {
    layout: 'editor',
    components: {
        EditorContentLayout,
    },
    data(){
        return {
            currentPopPreview: {},
            loading: false,
        }
    },
    async mounted(){
      await this.loadThemeGroups()
      await this.loadThemes({groupId: this.themeGroups[0].id})
    },
    computed: {
        ...mapState(useEditorStore, ['currentFormsByHandle']),
        ...mapState(useUiStore, ['editingPopHandler']),
        ...mapState(useThemesStore, ['themePackages', 'themeGroups']),
        ...mapWritableState(useThemesStore, ['currentTheme']),
        ...mapState(usePopsStore, ['microExamplePop']),
        ...mapState(useTemplatesStore, ['canSavePro']),
        parsedCurrentPopPreview(){
            return this.parseSalesPopProps(this.currentPopPreview)
        },
    },
    methods: {
        ...mapActions(useTemplatesStore, ['saveCurrentTemplate', 'applyToAll']),
        ...mapActions(useBaseOnboardingStore, ['showOnboardingStep']),
        ...mapActions(useThemesStore, ['loadThemeGroups', 'loadThemes']),
        parseSalesPopProps(theme = {}){
            return {
                pop: {
                    siteIntegration: {
                        settings: {
                            proof_background_color: theme.background_color,
                            proof_background_image_url: theme.background_image_url,
                            proof_font_color: theme.font_color,
                            proof_border_radius: parseInt(theme.border_radius),
                        }
                    }
                }
            }
        },
        async handleShowThemesForGroup(payload){
          const { show, groupId } = payload

          if(!this.themePackages[groupId]) { await this.loadThemes({groupId: groupId}) }

          return this.themePackages[groupId]
        },
        async handleSave(){
            try {
                this.loading = true
                await this.saveCurrentTemplate()
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
                this.currentPopPreview = {}
                await this.showOnboardingStep('button_back_to_pops_settings')
            }
        },
        async handleApplyToAll() {
            try {
                await this.applyToAll()
            } catch(e) {
                console.error(e)
            }
        },
        merge
    },
    destroyed(){
        this.currentTheme = {}
    },
    watch: {
        currentPopPreview(theme){
            this.currentTheme = theme
        },
        editingPopHandler(){
            this.currentTheme = {}
        }
    }
}
