
import { mapState } from 'pinia'
import { useUiStore } from "~/store/ui";

export default {
  layout: 'loading',
  watch: {
    loadingPreflight: {
      immediate: true,
      handler(loading) {
        if (loading) return

        this.$router.push('/dashboard')
      }
    },
  },
  computed: {
    ...mapState(useUiStore, ['loadingPreflight']),
  }
}
