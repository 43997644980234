
import { mapState } from 'pinia'
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'

export default {
    computed: {
        ...mapState(usePricingStore, [
            'limitReached',
        ]),
        ...mapState(useSiteStore, [
          'currentSite',
        ]),
        rightPlan() {
          if (this.currentSite.utils.with_pricing_plan) {
            return this.currentSite.pricing_plan.name
          }
          if (this.currentSite.plan === 'paid') return 'Legacy Growth'
          return this.currentSite.plan
        },
        nextPricingPlan() {
          return this.currentSite.pricing_plan.next_pricing_plan.name
        }
    },
    methods: {
      handleButtonClick() {
        return this.$router.push('/pricing')
      }
    }
}
