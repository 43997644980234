import { render, staticRenderFns } from "./PopsMetricsConversionByPopTypesAggregationsChart.vue?vue&type=template&id=1e8c67b0&scoped=true&lang=pug"
import script from "./PopsMetricsConversionByPopTypesAggregationsChart.vue?vue&type=script&lang=js"
export * from "./PopsMetricsConversionByPopTypesAggregationsChart.vue?vue&type=script&lang=js"
import style0 from "./PopsMetricsConversionByPopTypesAggregationsChart.vue?vue&type=style&index=0&id=1e8c67b0&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e8c67b0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BarChart: require('/opt/build/repo/components/Charts/BarChart.vue').default,HorizontalBarChart: require('/opt/build/repo/components/Charts/HorizontalBarChart.vue').default,EmptyChart: require('/opt/build/repo/components/EmptyState/EmptyChart.vue').default,Card: require('/opt/build/repo/components/Layout/Card.vue').default})
