import { render, staticRenderFns } from "./Toasts.vue?vue&type=template&id=2a227fc4&scoped=true&lang=pug"
import script from "./Toasts.vue?vue&type=script&lang=js"
export * from "./Toasts.vue?vue&type=script&lang=js"
import style0 from "./Toasts.vue?vue&type=style&index=0&id=2a227fc4&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a227fc4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Toast: require('/opt/build/repo/components/DataDisplay/Toast.vue').default})
