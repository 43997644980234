
import BasicLayout from '~/layouts/basic.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Dropdown from 'v-dropdown'
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useUiStore } from '~/store/ui'
import { usePricingStore } from '~/store/pricing'
import { usePopMetricsStore } from "~/store/pops/metrics";
import { useNewCustomerOnboardingStore } from "~/store/onboardings/newCustomer";
import { useBaseOnboardingStore } from "~/store/onboardings/baseOnboarding";
import { useSiteStore } from '~/store/site'

export default {
    head() {
        return {
            title: 'SalesPop | Metrics',
        }
    },
    components: {
        BasicLayout,
        'v-dropdown': Dropdown,
        DatePicker,
    },
    data(){
        return {
            dateRange: new Date(),
            selectedPeriod: {},
            selectedPeriodLabel: 'Today',
            usingDatePicker: false,
            changedPeriodSelect: '',
            shownDropdown: '',
        }
    },
    created() {
      this.handlePeriodSelect(this.metricPeriods.find(period => period.label === this.selectedPeriodLabel))
        this.changedPeriodSelect =  '';

      if(!this.loading && !this.$isPro()){
        this.redirectToDashboard()
      }
    },
    destroyed() {
      usePopMetricsStore().$reset()
    },
    watch: {
        dateRange(range) {
            this.usingDatePicker = true

            const newFrom = this.$moment(range[0])
            const newTo = this.$moment(range[1])

            if(!newFrom.isValid() || !newTo.isValid()) return

            this.selectedPeriod = { current: { from: newFrom, to: newTo } }

            if(newFrom.format('DD/MM/YYYY') === newTo.format('DD/MM/YYYY')) {
                this.selectedPeriodLabel = `${newFrom.format('LL')}`
            } else {
                this.selectedPeriodLabel = `${newFrom.format('LL')} - ${newTo.format('LL')}`
            }
        },
        watchTrigger(){
            if (this.shownDropdown === true && this.usingDatePicker === false && !this.changedPeriodSelect) {
                this.showOnboardingStep('pops_metrics_calendar')
            }
            else if (this.shownDropdown === false && this.usingDatePicker === false  && !this.changedPeriodSelect) {
                this.showOnboardingStep('pops_metrics_selector')
            }
            else if (this.shownDropdown === false && this.usingDatePicker === true || this.changedPeriodSelect) {
                this.showOnboardingStep('plans_and_pricing')
            }
        },
        loadingMetrics(newVal, oldVal) {
            if (oldVal === true && newVal === false) {
                if (this.onboarding?.currentStep?.id === 'pops_menu_metrics') {
                    this.showOnboardingStep('pops_metrics_selector');
                }
            }
        },
        loading(){
          if(!this.$isPro()) {
           this.redirectToDashboard()
          }
        }
    },
    methods: {
        ...mapActions(useBaseOnboardingStore, ['showOnboardingStep']),
        handleChangeDropdownState(value) {
            this.shownDropdown = value
        },
        handlePeriodSelect(period) {
            this.usingDatePicker = false
            if(this.selectedPeriod === period) return
            this.selectedPeriod = period
            this.selectedPeriodLabel = period.label
            this.$refs.periodDropdown?.visible?.()

            this.changedPeriodSelect =  period.label
        },
        redirectToDashboard() {
          this.$router.push('/dashboard')
          this.showRedirectToDashboardChangeModal = true;
        },
    },
    computed: {
        watchTrigger() {
            return this.usingDatePicker, this.changedPeriodSelect, this.shownDropdown;
        },
        ...mapState(usePricingStore, ['plan']),
        ...mapState(useUiStore, [
            'loadingPreflight',
            'metricPeriodsList',
            'metricPeriods'
        ]),
        ...mapState(usePopMetricsStore, [
            'loadingConversionAggregations',
            'loadingDiscountCodeAggregations',
            'loadingActionsCount',
            'loadingTemplatesClicksAggregations',
            'loadingTemplatesViewsAggregations',
        ]),
        ...mapState(useNewCustomerOnboardingStore, ['onboarding']),
        ...mapState(useSiteStore, ['loading']),
        ...mapWritableState(useUiStore, ['showRedirectToDashboardChangeModal']),
        loadingMetrics() {
            return this.loadingConversionAggregations ||
                   this.loadingDiscountCodeAggregations ||
                   this.loadingActionsCount ||
                   this.loadingTemplatesClicksAggregations ||
                   this.loadingTemplatesViewsAggregations
        },
        unsafeDates(){
            const startDate = this.$moment('08/09/2020', 'DD/MM/YYYY')
            const { current } = this.selectedPeriod
            return current?.from?.isSameOrBefore(startDate)
        },
    }
}
