
export default {
    props: {
        icon: {
            type: String,
            default: 'check'
        },
        secondaryIcon: {
          type: String,
          default: ''
        },
        submenuText: {
          type: Boolean,
          default: false
        },
        text: {
            type: String,
            default: 'text'
        },
        to: {
            type: String,
            default: ''
        },
        level: {
            type: Number,
            default: 0
        },
        external: {
            type: Boolean,
            default: false,
        },
        prefetch: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        decideNavigation(navigate, $event){
            if(this.external) return
            navigate($event)
        }
    }
}
