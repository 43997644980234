
export default {
    props: {
        loading: {
            type: Boolean,
            default: () => false
        },
        title: {
            type: String,
            default: 'Theme name'
        },
        description: {
            type: String,
            default: 'This theme kit contains X different pop options.'
        },
        pro: {
            type: Boolean,
            default: () => false
        },
        salesPopProps: {
            type: Object,
            default: () => ({
                backgroundColor: '#fff',
                backgroundImg: '',
                textColor: '#333'
            })
        }
    }
}
