import { render, staticRenderFns } from "./EventsTable.vue?vue&type=template&id=48bb9eb2&scoped=true&lang=pug"
import script from "./EventsTable.vue?vue&type=script&lang=js"
export * from "./EventsTable.vue?vue&type=script&lang=js"
import style0 from "./EventsTable.vue?vue&type=style&index=0&id=48bb9eb2&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bb9eb2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tag: require('/opt/build/repo/components/DataDisplay/Tag.vue').default,EmptyPopEvents: require('/opt/build/repo/components/EmptyState/EmptyPopEvents.vue').default,EmptyMediaItem: require('/opt/build/repo/components/EmptyState/EmptyMediaItem.vue').default,Card: require('/opt/build/repo/components/Layout/Card.vue').default})
