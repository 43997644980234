
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
        extends: Doughnut,
        mixins: [reactiveProp],
        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            chartData: {
                type: Object,
                default: null
            },
        },
        computed: {
            chartOptions() {
                const defaultOptions = {
                    maintainAspectRatio: false,
                    defaultFontFamily: "'CircularStd', 'helvetica', 'sans-serif'",
                    layout: {
                        padding: 20,
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        titleFontFamily: "'CircularStd', 'helvetica', 'sans-serif'",
                        bodyFontFamily: "'CircularStd', 'helvetica', 'sans-serif'"
                    }
                }
                return _.merge(defaultOptions, this.options)
            }
        },
        mounted() {
            this.renderChart(this.chartData, this.chartOptions)
        },
        watch: {
            chartData(data){
                this.renderChart(data, this.chartOptions)
            }
        }
}
