
import { mapState, mapWritableState } from 'pinia'
import { useUiStore } from '~/store/ui'
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'

export default {
    data(){
        return {}
    },
    computed: {
        ...mapState(useUiStore, ['loadingPreflight']),
        ...mapState(usePricingStore, ['plan', 'currentCharge', 'popupPerks']),
        ...mapState(useSiteStore, ['currentSite']),
        ...mapWritableState(useUiStore, ['planChangeModal']),

        planName() {
            if(this.currentSite.utils.with_pricing_plan) return this.currentSite.pricing_plan.name

            return this.$isPro() ? 'Legacy Growth' : 'Legacy Free'
        },
        maxSessions() {
            if(this.currentSite.utils.with_pricing_plan) return this.currentSite.pricing_plan.max_sessions
        }
    },
}
