
import { mapWritableState } from 'pinia'
import { useTemplatesStore } from '~/store/templates'

export default {
    props: {
        minimumCountLabel: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            minuteFactor: {
                day: 1440,
                hour: 60,
                minute: 1,
            },
            granularity: 'hour',
            minimum: 36,
        }
    },
    computed: {
        ...mapWritableState(useTemplatesStore, ['currentTemplate']),
        lookbackTime: {
            get(){
                return this.currentTemplate.proof_summary_time_range / this.minuteFactor[this.granularity]
            },
            set(lookbackTime){
                this.currentTemplate.proof_summary_time_range = this.minuteFactor[this.granularity] * lookbackTime
            }
        },
        summaryMinimumCount: {
            get() {
                return this.currentTemplate.proof_summary_minimum_count
            },
            set(newValue) {
                this.currentTemplate.proof_summary_minimum_count = newValue > 0 ? newValue : 0
            }
        }
    },
    watch: {
        summaryMinimumCount(newValue) {
            this.summaryMinimumCount = newValue
        }
    }
}
