
import Logo from '~/static/icons/brand/logo.svg'
import MenuIcon from '~/static/icons/common/menu.svg'
import { mapWritableState } from 'pinia'
import { useUiStore } from '~/store/ui'

export default {
    components: {
        Logo,
        MenuIcon
    },
    computed: {
        ...mapWritableState(useUiStore,[ 'sidebarIsOpen' ])
    },
}
