
import { mapWritableState } from 'pinia'
import { useTemplatesStore } from '~/store/templates'

export default {
    validations: {
    },
    computed: {
        ...mapWritableState(useTemplatesStore,['currentTemplate']),
        hoursBeforeObscure: {
            get() {
                return this.currentTemplate.proof_hours_before_obscure
            },
            set(hours){
                if(!hours) return
                return this.currentTemplate.proof_hours_before_obscure = +hours
            }
        },
        checked() {
            return this.hoursBeforeObscure === 0
        }
    },
    methods: {
        handleCheckboxClick() {
            if(this.hoursBeforeObscure) {
                this.currentTemplate.proof_hours_before_obscure = 0
            } else {
                this.hoursBeforeObscure = 1
            }
        }
    }
}
