
import { mapState } from 'pinia'
import { useTemplatesStore } from '~/store/templates'

export default {
    props: {
        top: {
            type: String,
            default: 'Jane in Miami, Florida',
        },
        middle: {
            type: String,
            default: 'Bought Kodak Polaroid Camera',
        },
        purchasedAt: {
            type: [String, Date],
            default: '2020-07-27T05:31:10.000Z'
        },
        eventType: {
            type: String
        },
        salesPopProps: {
            type: Object,
            default: () => {}
        },
        deleted: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        ...mapState(useTemplatesStore, ['templates']),
        date(){
            return this.$moment(this.purchasedAt).format('LLL')
        },
        template(){
            return this.templates.find(template => this.salesPopProps.handler === template.handle) ?? {}
        },
        parsedSalesPopProps(){
            const {template} = this
            return {
                backgroundColor: template.proof_background_color,
                backgroundImg: template.proof_background_img,
                textColor: template.proof_font_color,
                borderRadius: parseInt(template.proof_border_radius),
            }
        },
    },

}
