
import { SkeletonTheme } from 'vue-loading-skeleton'
export default {
    components: {
        SkeletonTheme
    },
    props: {
        value: {
            type: Boolean,
            default: () => false,
        },
        onLabel: {
            type: String,
            default: 'ON'
        },
        offLabel: {
            type: String,
            default: 'OFF'
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        disabled: {
            type: Boolean,
            default: () => false
        }
    },
    methods: {
        handleSwitch(){
            if(this.loading || this.disabled) return
            this.$emit('input', !this.value)
        }
    },
}
