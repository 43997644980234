
import StarIcon from '~/static/icons/common/star.svg'
import { mapState } from 'pinia'
import { useSiteStore } from '~/store/site'

export default {
    components: {
        StarIcon
    },
    props: {
        title: {
            type: String,
            default: () => {
                return "You haven't reviewed Sales Pop yet."
            }
        },
        titleReview: {
            type: String,
            default: () => {
                return 'Rate us'
            }
        },
        copy: {
            type: String,
            default: () => {
                return `Please rate your experience with Sales Pop`
            }
        }
    },
    computed: {
        ...mapState(useSiteStore, ['currentSite']),
    },
    methods: {
        async starClick(rating) {
            await this.$track({
                name: 'review_banner_star_selected',
                metadata: {
                    selected: rating,
                }
            })

            if(rating === 5 || rating === 4) {
                window.open('https://apps.shopify.com/shoppop?#modal-show=ReviewListingModal', '_blank').focus()
                return
            }

            this.$helpScoutMessage({
                subject: `Sales Pop - ${rating} star`,
                text: `Hi, I want to submit my feedback about Sales Pop.`,
                chat: false,
            })
        }
    }
}
