
export default {
    props: {
        progress: {
            type: [Number, String],
            default: 50,
        },
        color: {
            type: String,
            default: '#6F58FF'
        }
    }
}
