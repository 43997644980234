import { render, staticRenderFns } from "./TipTap.vue?vue&type=template&id=28121816&scoped=true&lang=pug"
import script from "./TipTap.vue?vue&type=script&lang=js"
export * from "./TipTap.vue?vue&type=script&lang=js"
import style0 from "./TipTap.vue?vue&type=style&index=0&id=28121816&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28121816",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/opt/build/repo/components/DataInput/Button.vue').default,ColorCheckBox: require('/opt/build/repo/components/DataInput/ColorCheckBox.vue').default,Card: require('/opt/build/repo/components/Layout/Card.vue').default,EmojiPicker: require('/opt/build/repo/components/DataInput/EmojiPicker.vue').default})
