import { render, staticRenderFns } from "./boost-sales.vue?vue&type=template&id=684b759c&scoped=true&lang=pug"
import script from "./boost-sales.vue?vue&type=script&lang=js"
export * from "./boost-sales.vue?vue&type=script&lang=js"
import style0 from "./boost-sales.vue?vue&type=style&index=0&id=684b759c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684b759c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/opt/build/repo/components/DataInput/Button.vue').default})
