
import Uploadcare from 'uploadcare-vue'
import UploadClient from '@uploadcare/upload-client'

export default {
    components: {
      Uploadcare
    },
    props: {
        value: String,
    },
    data(){
        return {
            imageFile: null,
            error: null,
            apiKey: process.env.UPLOAD_CARE_API_KEY,
            isOver: false,
        }
    },
    watch: {
      imageFile(file){
        if(!file) {
            this.$emit('input', "")
            return
        }
      }
    },
    computed: {
      uploadDisabled() {
          return this.files?.length === 0
      },
      cssVars(){
        return {
          '--background-image': `url('${this.value}')`,
        }
      }
    },
    methods:{
      async addFile(e, files) {
        this.isOver = false
        let droppedFiles = e?.dataTransfer?.files || files
        if (!droppedFiles) {
          this.error = 'No files loaded'
          return
        }
        if (droppedFiles?.length > 1) {
          this.error = 'Upload just one file'
          return
        }

        if(!droppedFiles[0].type.includes('image/')) {
          this.error = 'File is not an image'
          return
        }
        const client = new UploadClient({ publicKey: this.apiKey })
        const uploadedFile = await client.uploadFile(droppedFiles[0])
        this.$emit('input', uploadedFile.cdnUrl)
      },
      removeFile() {
        this.imageFile = null
        this.$emit('input', null)
        //TODO: Delete image from database
      },
      onSuccess(e) {
        this.$emit('input', e.cdnUrl)
      },
      onError(e) {
        console.log(e)
      },
    }
}
