
import { mapWritableState } from 'pinia'
import { useTemplatesStore } from "~/store/templates";

export default {
    computed: {
        ...mapWritableState(useTemplatesStore, ['currentTemplate']),
        QuantityThreshold: {
            get() {
                return this.currentTemplate.proof_low_inventory_quantity_threshold
            },
            set(value){
                if(!value) return
                if(+value < 1) {
                    return this.currentTemplate.proof_low_inventory_quantity_threshold = 1
                } else {
                    return this.currentTemplate.proof_low_inventory_quantity_threshold = +value
                }
            }
        }
    }
}
