
import { mapWritableState } from 'pinia'
import { useTemplatesStore } from '~/store/templates'

export default {
    data(){
        return {
            newtab: false
        }
    },
    computed: {
        ...mapWritableState(useTemplatesStore, ['currentTemplate']),
    }
}
