
export default {
    props: {
        icon: {
            type: String,
        },
        iconBoxProps: {
            type: Object,
        },
        stats: {
            type: Array,
            default: [],
        },
        label: {
            type: String,
            default: 'Some label'
        },
        help: {
            type: String,
        },
        loading: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        computedIconBoxProps(){
            return {
                icon: this.icon,
                ...this.iconBoxProps,
            }
        }
    }
}
