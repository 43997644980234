
import MinimalPopSvg from '~/static/icons/settings/minimal-pop.svg'
export default {
    components: {
        MinimalPopSvg
    },
    props: {
        size: {
            type: String,
            default: 'small'
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        changeSize(size){
            this.$emit('update:size', size)
            this.$emit('change')
        }
    }
}
