
import { SkeletonTheme } from 'vue-loading-skeleton'
import Qs from 'qs'

export default {
    components: {
        vDropdown: () => import('v-dropdown'),
        SkeletonTheme,
    },
    async mounted(){
        await this.searchProducts()
    },
    data() {
        return {
            open: false,
            products: [],
            searchTerm: null,
            currentPage: 1,
            selectedProduct: {},
            loadingProducts: false
        }
    },
    methods: {
        async searchProducts(title = '', perPage = 5, page = 1){
            this.loadingProducts = true
            try {
                const params = {
                    page,
                    per_page: perPage,
                    filter: { title }
                }

                const res = await this.$axios.$get(
                    `${process.env.API_V1_URL}/pops/products`,
                    {
                        params,
                        paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false })
                    }
                )
                this.products = res.data
            } catch (e) {
                console.error(e)
            } finally {
                this.loadingProducts = false
            }
        },
        triggerSearch(search){
            this.searchProducts(search)
        },
        selectProduct(product){
            this.selectedProduct = product
            this.open = false
            this.$emit('select', product)
        }
    }
}
