
import { mapState, mapActions } from 'pinia'
import { useMediaEditorStore } from '~/store/mediaEditor'

export default {
    layout: 'empty',
    props: {
        open: {
            type: Boolean,
            default: true,
        },
        projectId: {
            type: String,
        }
    },
    data(){
        return {
            editor: null,
            showProjects: false,
            loadingSave: false,
            selectedProjectId: this.projectId
        }
    },
    async mounted(){
        await this.loadMediaEditorUserToken()
        await this.initDesignHuddle()
    },
    computed: {
        ...mapState(useMediaEditorStore, {
            accessToken: 'userAccessToken',
            mediaTemplates: 'templates',
            activeEditor: 'activeEditor',
            projects: 'projects',
        }),
    },
    methods: {
        ...mapActions(useMediaEditorStore, [
            'loadMediaEditorUserToken',
            'initDesignHuddle',
            'changeEditorProject',
            'injectDesignHuddle'
        ]),
        inject(){
            if(!this.accessToken) setTimeout(this.inject, 100)
            this.injectDesignHuddle({
                elementId: 'editor-inject',
                projectId: this.selectedProjectId
            })
        },
        cancel(){
            this.$emit('update:open', false)
        },
        async done(){
            this.loadingSave = true
            this.$emit('change', this.selectedProjectId)
        },
        projectSelect(project){
            setTimeout(() => {
                this.selectedProjectId = project.project_id
                this.changeEditorProject({projectId: project.project_id })
                this.showProjects = false
            }, 500)
        }
    },
    watch: {
        open: {
            immediate: true,
            handler(open){
                if(!open) return
                this.$nextTick(this.inject)
            }
        },
        // activeEditor(activeEditor){
        //     activeEditor.handleExitEditor(console.log)
        // },
        projectId(projectId) {
            this.selectedProjectId = projectId
        }
    }
}
