
    import { useUpsellsMetricsStore } from "~/store/upsells/metrics";
    import { useUiStore } from "~/store/ui";
    import { mapActions, mapState } from "pinia";
    import mapValues from "lodash/mapValues";
    import numeral from "numeral";

    export default {
        props: {
            period: Object,
        },
        data() {
            return {
                actionsDataMap: {
                    add_to_cart_view: {
                        title: 'Upsells Views',
                        icon: {
                            icon: 'eye',
                        },
                        slug: 'add_to_cart_view',
                        help: 'How many times upsells were viewed'
                    },
                    add_to_cart_button_click: {
                        title: 'Add to Cart clicks',
                        icon: {
                            icon: 'mouse-alt',
                            color: this.$nuxt.$colors.green,
                        },
                        slug: 'add_to_cart_button_click',
                        help: 'How many times Add to cart button was clicked'
                    },
                    add_to_cart_close: {
                        title: 'Upsells Closed',
                        icon: {
                            icon: 'times-circle',
                            color: this.$nuxt.$colors.error,
                        },
                        slug: 'add_to_cart_close',
                        help: 'How many times Upsells were closed'
                    },
                    upsells_conversions_count: {
                        title: 'Conversions Count',
                        icon: {
                            icon: 'usd-circle',
                            color: this.$nuxt.$colors.green,
                        },
                        slug: 'upsells_conversions_count',
                        help: 'Amount of conversions'
                    },
                    upsells_conversion_rate: {
                        title: 'Conversion Rate',
                        icon: {
                            icon: 'percentage',
                        },
                        slug: 'upsells_conversion_rate',
                        help: 'Conversion rate'
                    }
                },
            }
        },
        watch: {
            period: {
                deep: true,
                immediate: true,
                handler(value) {
                    if(!value) return

                    this.loadActionsCount({ startDate: value.from, endDate: value.to })
                }
            }
        },
        methods: {
            ...mapActions(useUpsellsMetricsStore, ['loadActionsCount', 'loadConversionsTotals']),
        },
        computed: {
            ...mapState(useUpsellsMetricsStore, [
              'loadingActionsCount',
              'actionsCount',
            ]),
            ...mapState(useUiStore, ['loadingPreflight']),
            kpiActionsObjects() {
                return mapValues(this.actionsDataMap, (kpi, _name) => {
                    return {
                        ...kpi,
                        stats: [numeral(this.actionsCount.aggregations[kpi.slug]).format('0,0')],
                    }
                })
            },
        },
    }
