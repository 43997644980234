
export default {
    props: {
        correctPlanName: '',
        daysRemaining: '',
    },
    computed: {
        dateOfRenewAnnualPlan() {
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + this.daysRemaining);
            return this.$moment.utc(currentDate).format('DD/MM/YYYY');
        },
    }
}
