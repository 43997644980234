
    import numeral from "numeral";
    import { mapState, mapActions } from 'pinia'
    import { usePopMetricsStore } from "~/store/pops/metrics";

    export default {
        props: {
            periodLabel: String,
            period: Object
        },
        data() {
            return {
                omittedCurrencies: [],
                dataMap: {},
                chartOptions: {
                    scales: {
                        yAxes: [
                            {
                                gridLines: { display: false },
                                ticks: { display: true, min: 0 },
                            }
                        ]
                    },
                    cornerRadius: 7,
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            label: (tooltipItem, data) => {
                                let label = data.datasets[tooltipItem.datasetIndex].label
                                let value = this.formattedNumericValue(
                                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                                )
                                let count = this.formattedNumericValue(
                                    data.datasets[tooltipItem.datasetIndex].count[tooltipItem.index],
                                    '0,0'
                                )

                                return value ? `${label} (Count: ${count}, Total: ${value})` : ''
                            }
                        }
                    }
                }
            }
        },
        watch: {
            period: {
                deep: true,
                immediate: true,
                handler(value) {
                    if(!value) return

                    this.omittedCurrencies = []
                    this.loadConversionAggregations({ startDate: value.from, endDate: value.to })
                }
            }
        },
        methods: {
            ...mapActions(usePopMetricsStore, ['loadConversionAggregations', 'colorFromMD5']),
            handleFiltering(currency) {
                if(this.omittedCurrencies.includes(currency)) {
                    this.omittedCurrencies = this.omittedCurrencies.filter(entry => entry !== currency)
                } else {
                    this.omittedCurrencies.push(currency)
                }
            },
            formattedNumericValue(value, format = '0,0.00') {
                return numeral(value).format(format)
            },
        },
        computed: {
            ...mapState(usePopMetricsStore, ['conversionAggregations', 'loadingConversionAggregations', 'granularityData']),
            conversionsChartData() {
                let datasets = []
                let labels = []
                this.dataMap = {}

                if(this.conversionsExist) {
                    let currencies = this.conversionAggregations.currencies

                    currencies.forEach((currency) => {
                        let color = this.colorFromMD5(currency)

                        this.dataMap[currency] = {
                            color: color,
                            data: [],
                            count: [],
                            total_amount: 0,
                            total_count: 0,
                            currency: currency,
                        }
                    })

                    Object.entries(this.conversionAggregations.aggregations).forEach(([date, data]) => {
                        labels.push(this.$moment.utc(date).format(this.conversionsDateFormat))

                        Object.keys(this.dataMap).forEach((key) => {
                            this.dataMap[key].data.push(data[key]?.amount)
                            this.dataMap[key].count.push(data[key]?.count || null)
                            this.dataMap[key].total_amount += Number(data[key]?.amount || 0)
                            this.dataMap[key].total_count += Number(data[key]?.count || 0)
                        })
                    })

                    let filteredData = Object.keys(this.dataMap).reduce((data, currency) => {
                        if(!this.omittedCurrencies.includes(currency)) {
                            data[currency] = this.dataMap[currency]
                        }

                        return data
                    }, {})

                    Object.keys(filteredData).forEach((key) => {
                        datasets.push({
                            label: key,
                            backgroundColor: this.dataMap[key]?.color,
                            count: this.dataMap[key]?.count,
                            data: this.dataMap[key]?.data,
                            stack: key
                        })
                    })
                }

                return { labels, datasets, period: this.period }
            },
            conversionsExist() {
                return this.conversionAggregations.currencies.length
            },
            totalConversionsCount() {
                if(!this.conversionsExist) return 0
                return Object.values(this.dataMap).reduce((total, entry) => total + entry.total_count, 0)
            },
            conversionsDateFormat() {
                return this.granularityData.dateFormats[this.conversionAggregations.granularity]
            }
        },
    }
