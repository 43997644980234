
import { mapState, mapActions } from 'pinia'
import { useUiStore } from '~/store/ui'
import { useUpsellsStore } from '~/store/upsells.js'

export default {
    head() {
        return {
            title: 'SalesPop | Pop Library',
        }
    },
    async mounted(){
      await this.loadUpsellsList()
    },
    watch: {
        loadingPreflight: {
            immediate: true,
            async handler(loading){
                if(loading) return
            }
        }
    },
    methods: {
        ...mapActions(useUpsellsStore, [
            'loadUpsellsList',
        ]),
        async connectUpsell(upsell_type) {
          await this.$emit('enabledToggled', upsell_type)
          await this.loadUpsellsList()
        },
        upsellRoute(key) {
          const pathMapping = {
            add_to_cart: '/upsells/rules-and-settings/add-to-cart',
          }

          return pathMapping[key]
        },
        upsellIcon(key) {
          const iconMapping = {
            add_to_cart: 'shopping-cart-alt',
          }

          return iconMapping[key]
        },
        isUpsellActive(key) {
          const keyMapping = {
            add_to_cart: 'add-to-cart',
          }

          return keyMapping[key] === this.$route.path.split('/').pop()
        }
    },
    computed: {
        ...mapState(useUiStore, ['loadingPreflight']),
        ...mapState(useUpsellsStore, ['upsells']),
    }
}
