
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useUiStore } from '~/store/ui'
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'
import { useAddToCartStore } from '~/store/upsells/add_to_cart'

export default {
  data() {
    return {
      confirmLeave: false,
      isDirty: false
    }
  },
  watch: {
    addToCart: {
      deep: true,
      handler() {
        this.isDirty = !_.isEqual(this.originalAddToCart, this.addToCart)
      }
    },
  },
  computed: {
    ...mapState(useSiteStore, ['currentSite']),
    ...mapState(usePricingStore, ['plan']),
    ...mapState(useUiStore, ['loadingPreflight', 'planChangeModal']),
    ...mapWritableState(useAddToCartStore, ['loading', 'addToCart', 'originalAddToCart']),
    sidebarOptions() {
      return [{
        text: 'Go Back',
        icon: 'arrow-left',
        route: this.isDirty ? '' : '/upsells/rules-and-settings/add-to-cart/',
        id: "upsellsRulesAndSSettingsAddToCartGoBackButton",
        clickAction: () => {
          this.confirmLeave = true
          this.leaveRoute = '/upsells/rules-and-settings/add-to-cart/'
        },
      }, {
        text: 'Discounts',
        icon: 'percentage',
        route: '/upsells/rules-and-settings/add-to-cart/discounts'
      }, {
        text: 'Settings',
        icon: 'sliders-v-alt',
        route: '/upsells/rules-and-settings/add-to-cart/settings',
        id: "upsellsRulesAndSettingAddToCartSettingsNavbarSettingsButton",
      }, {
        text: 'Design',
        icon: 'brush-alt',
        route: '/upsells/rules-and-settings/add-to-cart/design',
        id: "upsellsRulesAndSettingAddToCartDesignNavbarDesignButton",
      },]
    },
  },
  methods: {
    ...mapActions(useAddToCartStore, ['saveAddToCart', 'loadAddToCart']),
    handlefunctionClick(option) {
      option.clickAction?.()
    },
    async handleSave({ leave }) {
      await this.saveAddToCart()

      if (leave) this.$router.push(this.leaveRoute)
    },
  },
}
