
import { mapActions, mapState } from 'pinia'
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'
import { usePopMetricsStore } from "~/store/pops/metrics";
import { useBaseOnboardingStore } from '~/store/onboardings/baseOnboarding'

export default {
    props: {
        loading: {
            type: Boolean,
            default: true,
        }
    },
    data() {
      return {
        showSubmenu: false,
      }
    },
    components: {
        Logo: ()=> import('~/static/icons/brand/logo-pop.svg'),
    },
    methods: {
      ...mapActions(useBaseOnboardingStore, ['startOnboarding']),
    },
    computed: {
        ...mapState(useSiteStore, ['currentSite', 'phoneSupportAvailable']),
        ...mapState(usePricingStore, {
            maximumSessions: s => s.maximumSessions,
            limitReached: s => s.limitReached,
        }),
        ...mapState(usePopMetricsStore, [
          'overviewConversionsTotals',
          'overviewConversionsTotalsStrings'
        ]),
        ...mapState(useBaseOnboardingStore, ['shopOnboardings']),
        addToCartUpsellsOnboarding(){
          return this.shopOnboardings.find((onboarding) => onboarding.type === 'add_to_cart_upsells')
        },
        discountPopsOnboarding(){
          return this.shopOnboardings.find((onboarding) => onboarding.type === 'discount_pops')
        },
        newCustomerOnboarding(){
          return this.shopOnboardings.find((onboarding) => onboarding.type === 'new_customer')
        },
        unlimited() {
            return this.currentSite.pricing_plan?.unlimited
        },
        hasAtLeast5Conversions() {
            return this.overviewConversionsTotals.total_count >= 5
        },
    },
}
