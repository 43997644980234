import { render, staticRenderFns } from "./PopsMetricsActionsCountCards.vue?vue&type=template&id=1fc60a3d&scoped=true&lang=pug"
import script from "./PopsMetricsActionsCountCards.vue?vue&type=script&lang=js"
export * from "./PopsMetricsActionsCountCards.vue?vue&type=script&lang=js"
import style0 from "./PopsMetricsActionsCountCards.vue?vue&type=style&index=0&id=1fc60a3d&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fc60a3d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KpiCard: require('/opt/build/repo/components/DataDisplay/KpiCard.vue').default,Button: require('/opt/build/repo/components/DataInput/Button.vue').default,Card: require('/opt/build/repo/components/Layout/Card.vue').default})
