
import numeral from "numeral";

export default {
    props: {
        invoices: {
            type: Array,
            default: () => []
        },
        currentSite: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: () => false
        }
    },
    methods: {
        numeral,
        parseDate(date){
            return this.$moment(date).format('ll')
        }
    },
    computed: {
        isLegacySite() {
            return this.currentSite.utils.new_or_old_pricing_tier === 'new'
        }
    }
}
