import { render, staticRenderFns } from "./PopTypeChooser.vue?vue&type=template&id=85c30f4a&scoped=true&lang=pug"
import script from "./PopTypeChooser.vue?vue&type=script&lang=js"
export * from "./PopTypeChooser.vue?vue&type=script&lang=js"
import style0 from "./PopTypeChooser.vue?vue&type=style&index=0&id=85c30f4a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85c30f4a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BigToggleToken: require('/opt/build/repo/components/DataInput/BigToggleToken.vue').default})
