import { render, staticRenderFns } from "./PopPagesFilterForm.vue?vue&type=template&id=d8627c30&scoped=true&lang=pug"
import script from "./PopPagesFilterForm.vue?vue&type=script&lang=js"
export * from "./PopPagesFilterForm.vue?vue&type=script&lang=js"
import style0 from "./PopPagesFilterForm.vue?vue&type=style&index=0&id=d8627c30&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8627c30",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconBox: require('/opt/build/repo/components/DataDisplay/IconBox.vue').default,BigToggleToken: require('/opt/build/repo/components/DataInput/BigToggleToken.vue').default,FilterInput: require('/opt/build/repo/components/DataInput/FilterInput.vue').default,Tag: require('/opt/build/repo/components/DataDisplay/Tag.vue').default})
