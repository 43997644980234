
import numeral from "numeral";
import { mapState, mapActions } from 'pinia'
import { usePopMetricsStore } from "~/store/pops/metrics";

export default {
    props: {
        periodLabel: String,
        period: Object,
    },
    data() {
        return {
            omittedDiscountCodes: [],
            dataMap: {},
            chartOptions: {
                scales: {
                    yAxes: [
                        {
                            gridLines: { display: false },
                            ticks: { display: true, min: 0 },
                        }
                    ]
                },
                cornerRadius: 7,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let label = data.datasets[tooltipItem.datasetIndex].label
                            let count = this.formattedNumericValue(
                                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                '0,0'
                            )

                            return count ? `${label} (Count: ${count})` : ''
                        }
                    }
                }
            }
        }
    },
    watch: {
        period: {
            deep: true,
            immediate: true,
            handler(value) {
                if(!value) return

                this.omittedDiscountCodes = []
                this.loadDiscountCodeAggregations({ startDate: value.from, endDate: value.to, action: 'pop_click' })
            }
        }
    },
    methods: {
        ...mapActions(usePopMetricsStore, ['loadDiscountCodeAggregations', 'colorFromMD5']),
        handleFiltering(discountCode) {
            if(this.omittedDiscountCodes.includes(discountCode)) {
                this.omittedDiscountCodes = this.omittedDiscountCodes.filter(entry => entry !== discountCode)
            } else {
                this.omittedDiscountCodes.push(discountCode)
            }
        },
        formattedNumericValue(value, format = '0,0.00') {
            return numeral(value).format(format)
        },
    },
    computed: {
        ...mapState(usePopMetricsStore, ['discountCodeAggregations', 'loadingDiscountCodeAggregations', 'granularityData']),
        discountCodesChartData() {
            let datasets = []
            let labels = []
            this.dataMap = {}

            if(this.discountCodesExist) {
                let discountCodes = this.discountCodeAggregations.discount_codes

                discountCodes.forEach((discountCode) => {
                    let color = this.colorFromMD5(discountCode)

                    this.dataMap[discountCode] = {
                        color: color,
                        data: [],
                        discountCode: discountCode,
                    }
                })

                Object.entries(this.discountCodeAggregations.aggregations).forEach(([date, data]) => {
                    labels.push(this.$moment.utc(date).format(this.discountCodesDateFormat))

                    Object.keys(this.dataMap).forEach((key) => {
                        this.dataMap[key].data.push(data[key] || 0)
                    })
                })

                let filteredData = Object.keys(this.dataMap).reduce((data, discountCode) => {
                    if(!this.omittedDiscountCodes.includes(discountCode)) {
                        data[discountCode] = this.dataMap[discountCode]
                    }

                    return data
                }, {})

                Object.keys(filteredData).forEach((key) => {
                    datasets.push({
                        label: key,
                        backgroundColor: this.dataMap[key]?.color,
                        data: this.dataMap[key]?.data,
                        stack: key
                    })
                })
            }

            return { labels, datasets, period: this.period }
        },
        discountCodesExist() {
            return this.discountCodeAggregations.discount_codes.length
        },
        totalDiscountCodesCount() {
            if(!this.discountCodesExist) return 0
            return Object.values(this.discountCodeAggregations.total_count).reduce((total, entry) => total + entry, 0)
        },
        discountCodesDateFormat() {
            return this.granularityData.dateFormats[this.discountCodeAggregations.granularity]
        }
    },
}
