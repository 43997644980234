
export default {
    props: {
        value: {
            type: String,
            default: '',
        }
    },
    data(){
        return {
            localValue: this.value,
        }
    },
    methods: {
        reset(){ this.localValue = ''}
    }
}
