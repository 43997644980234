
export default {
    props: {
        perks: {
            type: Array,
            default: [],
        },
        maxSessions: {
            type: Number,
            default: null
        }
    }
}
