
import BasicLayout from '~/layouts/basic'
import { mapState } from 'pinia'
import { useSiteStore } from "~/store/site";
export default {
    head() {
        return {
            title: 'SalesPop | Profile',
        }
    },
    components: {
        BasicLayout
    },
    computed: {
        ...mapState(useSiteStore, ['currentSite']),
        tabs() {
            const tabs = [{
                route: '/profile/subscription',
                text: 'Subscription'
            }]

            if(this.$source() === 'shopify'){
                tabs.push({
                    route: '/profile/invoices',
                    text: 'Invoices'
                })
            }
        }
    }
}
