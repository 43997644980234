import { render, staticRenderFns } from "./DiscountContentEditor.vue?vue&type=template&id=bb11ad12&scoped=true&lang=pug"
import script from "./DiscountContentEditor.vue?vue&type=script&lang=js"
export * from "./DiscountContentEditor.vue?vue&type=script&lang=js"
import style0 from "./DiscountContentEditor.vue?vue&type=style&index=0&id=bb11ad12&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb11ad12",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmojiPicker: require('/opt/build/repo/components/DataInput/EmojiPicker.vue').default,ErrorTag: require('/opt/build/repo/components/DataDisplay/ErrorTag.vue').default,Tag: require('/opt/build/repo/components/DataDisplay/Tag.vue').default,Button: require('/opt/build/repo/components/DataInput/Button.vue').default})
