
import BasicLayout from '~/layouts/basic'
import { mapState } from 'pinia'
import { useEditorStore } from '~/store/editor'
import { useUiStore } from '~/store/ui'
import { usePopsStore } from '~/store/pops'
import { useIntegrationsStore } from '~/store/integrations'
import { usePricingStore } from '~/store/pricing'
import { useTemplatesStore } from '~/store/templates'
export default {
    components: {
        BasicLayout,
        vDropdown: () => import('v-dropdown')
    },
    props: {
        saveEnabled: {
            type: Boolean,
            default: () => true
        },
        examplePopProps: {
            type: Object,
            default: null
        },
        applyAllActive: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            upgradeModalOpen: false,
            intendedProIntegration: null,
        }
    },
    computed: {
        ...mapState(useEditorStore, ['defaultRouteByHandle']),
        ...mapState(useUiStore, ['editingPopHandler']),
        ...mapState(usePopsStore, ['microExamplePop']),
        ...mapState(useIntegrationsStore, ['integrationHandleMap']),
        ...mapState(usePricingStore, ['plan']),
        ...mapState(useTemplatesStore, {
            loadingTemplate: 'loading',
            canSavePro: 'canSavePro',
            allFormsAreValid: 'allFormsAreValid',
        }),
        currentIntegration(){
            return this.integrationHandleMap[this.editingPopHandler?.integrationHandle] ?? {}
        },
        canSave(){
            return this.canSavePro && this.allFormsAreValid
        },
        examplePop() {
            return this.examplePopProps || this.microExamplePop
        }
    },
    methods:{
        handlePopChange(e, pop){
            this.$refs.integrationTypeDropdown?.visible()
            if(pop.pro && !this.$isPro()) {
                this.upgradeModalOpen = true
                this.intendedProIntegration = pop
                return
            }
            this.upgradeModalOpen = false
            this.intendedProIntegration = null
            useUiStore().setTemplateHandler(e)
            this.$nextTick(() => this.$router.push(this.defaultRouteByHandle[e]))
        }
    },
}
