
export default {
    props: {
        value: {
            type: Boolean,
            default: () => false
        },
        id: {
          type: Number
        },
        themes: {
          type: Array
        },
        separator: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            show: this.value
        }
    },
    methods: {
      handleShowThemesForGroup() {
        this.show = !this.show

        this.$emit('show-themes-for-group', {
          show: this.show,
          groupId: this.id,
        });
      },
      handleScrollInto() {
        this.$nextTick(()=>this.$refs.accordionWrapper.scrollIntoView({
          behavior: "smooth",
          block: "end"
        }))
      }
    },
    watch: {
        value(val) {
            this.show = val
        },
        show(show){
          if(!show && !this.themes) return
          this.handleScrollInto()
        },
        themes() {
          if(!this.themes) return
          this.handleScrollInto()
        }
    },
}
