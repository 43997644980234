
import { mapState } from 'pinia'
import { mapWritableState, mapActions } from 'pinia'
import { useAddToCartStore } from '~/store/upsells/add_to_cart'
import { useSiteStore } from '~/store/site'

export default {
  async mounted() {
    await this.loadAddToCart()
  },
  validations: {
    title: {
      required: true,
    },
    trigger_button_class: {
      required: true,
    },
    quantity_input_class: {
      required: true,
    }
  },
  computed: {
    ...mapState(useSiteStore, ['parsedLocale']),
    ...mapWritableState(useAddToCartStore, ['addToCart']),
  },
  watch: {
    '$v.$invalid'(isInvalid) {
      this.$emit('valid', !isInvalid)
    }
  },
  methods: {
    ...mapActions(useAddToCartStore, ['loadAddToCart']),
    changeTitleValue(value) {
      this.addToCart.title = value
    },
    changeTriggerButtonClassValue(value) {
      this.addToCart.trigger_button_class = value
    },
    changeQuantityInputClassValue(value) {
      this.addToCart.quantity_input_class = value
    },
  },
}
