
export default {
    props: {
        to: {
            type: [String, Object],
            default: '/'
        },
        active: {
            type: Boolean,
            default: () => false
        },
        theme: {
            type: String,
            default: 'dark'
        },
        external: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        decideNavigation(navigate, $event){
            if(this.external) return
            navigate($event)
        }
    }
}
