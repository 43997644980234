import { render, staticRenderFns } from "./metrics.vue?vue&type=template&id=0bd14fe0&scoped=true&lang=pug"
import script from "./metrics.vue?vue&type=script&lang=js"
export * from "./metrics.vue?vue&type=script&lang=js"
import style0 from "./metrics.vue?vue&type=style&index=0&id=0bd14fe0&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd14fe0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Card: require('/opt/build/repo/components/Layout/Card.vue').default,Tag: require('/opt/build/repo/components/DataDisplay/Tag.vue').default,UpsellsMetricsActionsCountCards: require('/opt/build/repo/components/Upsells/Metrics/UpsellsMetricsActionsCountCards.vue').default,UpsellsMetricsConversionAggregationsChart: require('/opt/build/repo/components/Upsells/Metrics/UpsellsMetricsConversionAggregationsChart.vue').default})
