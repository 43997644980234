
import { mapActions } from 'pinia'
import { useUserStore } from '~/store/user'

export default {
  layout: 'loading',
  mounted() {
    this.setUserAuthToken()
    this.setAdminSessionToken()

    this.$router.push('/dashboard')
  },
  methods: {
    ...mapActions(useUserStore, ['setAuthToken', 'setAdminToken']),
    setUserAuthToken() {
      const token = this.$route.query.token
      if (token) {
        this.setAuthToken(token)
      } else {
        window.top.location.href = 'https://salespop.com'
      }
    },
    setAdminSessionToken() {
        const admin_token = this.$route.query.admin_token

        if(admin_token) {
            this.setAdminToken(admin_token)
        }
    }
  }
}
