
import Color from 'color'

export default {
    props: {
        value: {
            type: Boolean,
            default: () => true,
        },
        color: {
            type: String,
            default: '#e7e7e7',
        }
    },
    computed: {
        cssVars(){
            const theColor = Color(this.color)
            const isLight = theColor.isLight()
            return {
                '--background-color': this.color,
                '--check-color': isLight ? 'black' : 'white',
                '--border-color': theColor.luminosity() > 0.85 ? '#e7e7e7' : '',
            }
        }
    }
}
