
import EditorContentLayout from '~/layouts/editorContent.vue'
import BasicLayout from '~/layouts/basic.vue'
import { mapState, mapActions } from 'pinia'
import { useEditorStore } from '~/store/editor'
import { useTemplatesStore } from '~/store/templates'

export default {
    layout: 'editor',
    components: {
        EditorContentLayout,
        BasicLayout
    },
    validations: {},
    computed: {
        ...mapState(useEditorStore, ['currentFormsByHandle'])
    },
    methods: {
        ...mapActions(useTemplatesStore, ['saveCurrentTemplate', 'applyToAll']),
        async handleSave(){
            try {
                await this.saveCurrentTemplate()
            } catch (e) {
                console.error(e)
            }
        },
        async handleApplyToAll() {
            try {
                await this.applyToAll()
            } catch(e) {
                console.error(e)
            }
        }
    }
}
