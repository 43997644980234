import { render, staticRenderFns } from "./invoices.vue?vue&type=template&id=52080400&scoped=true&lang=pug"
import script from "./invoices.vue?vue&type=script&lang=js"
export * from "./invoices.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52080400",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InvoicesTable: require('/opt/build/repo/components/business/InvoicesTable.vue').default})
