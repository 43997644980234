
import StarIcon from '~/static/icons/common/star.svg'
export default {
    components: {
        StarIcon,
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        color: {
            type: String,
            default: 'orange'
        },
        starWidth: {
            type: String,
            default: '18px'
        }
    },
    methods: {
        clickRating(rating){
            this.$emit('input', rating)
        }
    },
    computed: {
        cssVars(){
            return {
                '--color': this.color,
                '--star-width': this.starWidth
            }
        }
    }
}
