
import { mapActions, mapState } from 'pinia'
import { usePopsStore } from '~/store/pops'
import { useSiteStore } from '~/store/site'

export default {
    props: {
        showPreview: {
          type: Boolean,
          default: true
        }
    },
    computed: {
      ...mapState(usePopsStore, [
          'staticMockedPop'
      ]),
      ...mapState(useSiteStore, [
        'currentSite', 'popBrandingUnchangeable', 'isChangingBrandingInProgress'
      ]),
    },
    methods: {
       ...mapActions(useSiteStore, [
         'patchBranding'
       ]),
    }
}
