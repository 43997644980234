
import EditorContentLayout from '~/layouts/editorContent.vue'
import { mapState, mapActions } from 'pinia'
import { useEditorStore } from '~/store/editor'
import { useTemplatesStore } from '~/store/templates'

export default {
    layout: 'editor',
    components: {
        EditorContentLayout,
    },
    data(){
        return {
            isOpen: true,
        }
    },
    computed: {
        ...mapState(useEditorStore, ['currentFormsByHandle'])
    },
    methods: {
        ...mapActions(useTemplatesStore, ['saveCurrentTemplate']),
        async handleSave(){
            try {
                await this.saveCurrentTemplate()
            } catch (e) {
                console.error(e)
            }
        },
        async projectCreated(){
            await this.handleSave()
            this.$router.push('/pops/rules-and-settings/settings')
        }
    }
}
