
import { mapState } from 'pinia'
import { useUiStore } from '~/store/ui'
export default {
    props: {
        language: {
            type: String,
            default: 'en',
        }
    },
    computed: {
        ...mapState(useUiStore, ['languages'])
    },
    methods: {
        handleLanguageChange(e){
            this.$emit('update:language', e.target.value)
            this.$emit('change')
        }
    }
}
